import styled, { keyframes } from "styled-components";
import * as colors from "../../styles/colors";
import { contentMaxWidth } from "../../data/constants";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.Dark4};
  color: ${colors.Primary3};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;

  .conteudo{
    width: 300px;
    padding: 16px 0;
  }

  .perguntas {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  .perguntas_abertas {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    h2 {
      width: 100%;
    }
  }

  .upperCase {
    text-align: center;
    text-transform: uppercase;
  }

  .btn {
    background-color: ${colors.Primary1};
    border: none;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 7px;
    padding-top: 2%;
  }

  .parag {
    text-align: left;
    font-size: 0.88rem;
    color: ${colors.Primary3};
  }

  .redes {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    
  }

  .icons {
    min-width: 100%;
    padding-top: 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icons img {
    width: 30px;
  }

  .atendimento {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4%;
    
    h2 {
      font-size: 1.3rem;

    }
  }
  
  .unit {
    font-size: 1rem;
    padding: 4% 0 0 0;
  }

  .cidade {
    font-size: .9rem;
    font-family: MontserratBold;
  }

  .small {
    color: ${colors.Light};
    font-family: MontserratRegular;
    font-size: .8rem;
    text-align: center;
  }

  .rodape {
    width: 100%;
    background-color: ${colors.Dark3};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1% 0;
  }

  .conteudo_rodape {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rodape img {
    width: 35px;
    margin-bottom: 0.5rem;
  }

  .text_rodape {
    color: ${colors.Dark1};
    text-align: center;
    font-style: italic;
    line-height: 1rem;
    font-size: 0.7rem;
  }

  @media (min-width: 1200px) {
    margin-top: 80px;

    .conteudo {
      display: flex;
      flex-direction: row-reverse;
      width: ${contentMaxWidth};
    }

    .perguntas {
      width: 50%;
      padding: 0;
      flex-direction: column-reverse;
      justify-content: center;
    }

    .upperCase {
      width: 100%;
      text-align: right;
    }

    .perguntas_abertas {
      width: 100%;
    }

    .btn {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: right;
    }

    .redes {
      width: 100%;
    }

    .redes h5 {
      width: 100%;
      text-align: right;
    }

    .icons {
      width: 100%;
      justify-content: flex-end;
      gap: 2%;
    }

    .atendimento {
      align-items: flex-start;
      padding: 1% 0;
    }

    .atend_web{
      align-items: flex-end;
    }

    .atend {
      width: 50%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-evenly;
    }

    h2{
      padding: 0;
    }

    .conteudo_rodape {
      width: ${contentMaxWidth};
    }

    .btn h4 {
      text-align: right;
    }
    
  }
`;
const divOneAnimation = keyframes`
  from{
    max-height:0px;
  }
    to{
    max-height:300px;
    }
`
export const DivOne = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  /* max-height: ${(props)=> (props.isOpen ? "300px" : "0px")}; */
  align-items: center;
  justify-content: center;
  /* transition: max-height 1s linear; */
  animation: ${divOneAnimation} 1s linear;
  overflow:hidden;
  width: 100%;

  .textOpen {
    width: 90%;
    text-align: left;
  }

  .textOpen a {
    color: ${colors.Light};
    text-decoration: underline;
  }

  .textOpen a:visited {
    color: ${colors.Light};
  }


  @media (min-width: 1200px) {
    .textOpen {
      width: 100%;
      text-align: right;
    }
  }
`;

