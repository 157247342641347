import React, {useEffect, useMemo, useRef, useState} from "react";
import {PartnerStoreCardList} from "../PartnerStoreCardList";
import {storesByUF} from "../../data/stores";
import {
    Container,
    FakeSelectContainer,
    FakeSelectInput,
    FakeSelectOption,
    FakeSelectOptionContainer,
    InputContainer,
} from "./styled";

export function StoresFilter({stores, setCity, setUF}) {
    const [selectedUF, setSelectedUF] = useState(undefined);
    const [UFList, setUFList] = useState([])
    const [selectedCity, setSelectedCity] = useState(undefined);
    const [cityList, setCityList] = useState([])
    const [storesList, setStoresList] = useState()

    const filterUF = (stores) => {
        const list = Object.keys(stores)
        setUFList(list)
    }

    const handleStateSelection = (newSelectedUF) => {
        //reset city
        setSelectedCity()
        setCity()
        
        setUF(newSelectedUF)
        setSelectedUF(newSelectedUF);
        changeState(newSelectedUF)
    };

    const handleCitySelection = (newSelectedCityName) => {
        setSelectedCity(newSelectedCityName)
        setCity(newSelectedCityName)
    };

    // get the cities availables from selected UF
    const changeState = (newSelectedUF) => {
        if(!newSelectedUF) {
            setCityList([])
            return
        }

        let cities = storesList[newSelectedUF].map((store) => {
            return store.city.split(' - ')[0]
        })

        const uniqueCities = [...new Set(cities)]
        uniqueCities.sort((a, b) => a.localeCompare(b))
        setCityList(uniqueCities)
    }
    
    useEffect(() => {
        if(!stores) return
        setStoresList(stores)
        filterUF(stores)
    }, [stores]);

    return (
        <Container>
            <InputContainer>
                <CustomSelect
                    selectedValue={selectedUF}
                    initialLabel="Selecione o estado"
                    options={UFList}
                    onSelection={handleStateSelection}
                />
                <CustomSelect
                    selectedValue={selectedCity}
                    initialLabel="Selecione uma cidade"
                    options={cityList}
                    onSelection={handleCitySelection}
                />
            </InputContainer>
            
        </Container>
    );
}

function CustomSelect({selectedValue, initialLabel, options, onSelection}) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const currentLabel = useMemo(() => {
        return selectedValue
            ? options.find((value) => value === selectedValue)
            : initialLabel;
    }, [selectedValue]);

    const toggleIsOpen = () => {
        const hasOptions = !!options.length;
        if (hasOptions) {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (value) => {
        onSelection(value);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <FakeSelectContainer ref={ref}>
            <FakeSelectInput onClick={toggleIsOpen} isOpen={isOpen}>
                {currentLabel}
            </FakeSelectInput>
            {isOpen && (
                <FakeSelectOptionContainer>
                    {options.map((value) => (
                        <FakeSelectOption
                            key={value}
                            onClick={() => handleOptionClick(value)}
                        >
                            {value}
                        </FakeSelectOption>
                    ))}
                </FakeSelectOptionContainer>
            )}
        </FakeSelectContainer>
    );
}
