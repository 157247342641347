import styled from "styled-components";
import * as colors from "../../styles/colors";
import {contentMaxWidth} from "../../data/constants";
import grayBackground from "../../assets/images/gray_background.png";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.Light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  
  .spacing {
    margin: 0 0 5rem 0;
  }
  
  .One {
    width: 80%;
    text-align: center;
    padding: 3vh 0;
    margin-top: 2rem;
  }

  .video {
    width: 100%;
    height: 30rem;
  }

  .video iframe {
    width: 100%;
    height: 100%;
  }

  .One h1 {
    color: ${colors.Dark0};
    font-size: 2.5rem;
  }

  .aspasUp {
    display: flex;
  }

  .One img {
    width: 1rem;
  }

  .descOne {
    width: 100%;
  }
  .descOne p {
    margin-top: 3rem;
    text-align: center;
    width: 100%;
  }

  .aspasDown {
    display: flex;
    justify-content: flex-end;
  }

  .mission_container {
    background: ${colors.GrayLight};
    padding: 30px 0;
  }

  .mission {
    width: 100%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    color: ${colors.Primary0};

  }

  .mission p {
    text-align: center;
    margin: 0 15px;
    color: ${colors.Dark1};
  }

  .mission h2 {
    padding-bottom: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .mission img {
    width: 32px;
    margin-bottom: 15px;
  }

  .bg_blue {
    width: 100%;
    background-image: url(${grayBackground});
    background-color: rgba(34, 69, 157, 1);
    background-size: 50%;
    background-blend-mode: overlay;
  }

  .bg_lightblue {
    width: 100%;
    background-color: ${colors.Primary3};
  }

  .equipe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .equipe_text {
    padding: 7% 0;
    width: 80%;
    text-align: center;
  }
  
  .equipe_text h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: ${colors.Primary0};
  }
  .equipe_text p {
    color: ${colors.Dark1};
  }

  .img_container {
    width: 100%;
  }

  .compromisso {
    padding: 3% 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .compromisso h2 {
    text-align: right;
    color: ${colors.Light};
    margin-bottom: 3%;
    font-size: 2rem;
  }

  .compromisso_conteudo {
    display: flex;
    flex-direction: column-reverse;
  }

  .parag {
    text-align: center;
    color: ${colors.Light};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 4% 0;
  }

  .ajuste {
    /* width: 80%; */
    padding: 3% 0 3% 1rem;
    font-weight: 300;
  }
  
  .ajuste h4 {
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    .compromisso {
        width: 100%;
    }

    img {
        width: 100%;
    }

    .parag h2 {
        text-align: center;
    }

    .parag {
        margin: 15px;
    }
  }

  @media (min-width: 1200px) {
    .One {
      width: ${contentMaxWidth};
    }

    .descOne {
      width: ${contentMaxWidth};
    }
    .descOne p {
      margin-top: 3rem;
      padding: 0 10%;
      text-align: center;
      width: 100%;
      font-size: 1.05rem;
    }

    .imagem {
      width: ${contentMaxWidth};
      padding: 0;
      height: 70vh;
    }

    .imagem img {
      height: 100%;
      object-fit: cover;
    }

    .mission_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mission {
      flex-direction: row;
      width: ${contentMaxWidth};
      align-items: flex-start;
      padding: 3% 0;
    }

    .bg_blue {
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
    }
    
    .bg_lightblue {
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
    }

    .equipe {
      max-width: ${contentMaxWidth};
      flex-direction: row;
    }

    .equipe img {
      width: 100%;
    }

    .equipe div {
      width: 50%;
      text-align: left;
    }

    .equipe_text {
      padding: 0 30px 0 0;
    }

    .compromisso_conteudo {
      display: flex;
      max-width: ${contentMaxWidth};
      flex-direction: row-reverse;
    }

    .compromisso_conteudo img {
      width: 100%;
    }

    .compromisso_conteudo div {
      width: 50%;
      text-align: right;
    }

    .img_comp img {
      margin-left: calc(75% - 1000px);
    }

    .img_container img {
      height: 100%;
    }

    .parag {
      text-align: right;
      align-items: flex-end;
      padding: 60px 60px 60px 30px;
      justify-content: center;
    }

    .video {
      max-width: ${contentMaxWidth};
    }
  }
`