import styled from "styled-components";
import * as colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 4px;
  padding: 8px;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 4px ${colors.Dark2};
  border-radius: 8px;
  overflow: hidden;
  background-color: ${colors.Light};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
`;

export const FiveStarsImage = styled.img`
  width: 180px !important;
`;

export const ClientName = styled.p`
  margin-top: 0.5rem;
  font-size: 1.4rem;
  color: ${colors.Dark0};
  font-weight: 700;
  line-height: 1.125rem;
  text-align: center;
`;

export const Testimonial = styled.p`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.Dark0};
  font-size: 0.7rem;
  line-height: 1.1rem;
  text-align: center;
`;
