import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PageNotFound from "../Pages/page404/index.js";
import Home from "../Pages/home/index.js";
import Products from "../Pages/products/index.js";
import Depoiments from "../Pages/depoiments/index.js";
import Shopping from "../Pages/shopping/index.js";
import Instalation from "../Pages/instalation/index.js";
import Mission from "../Pages/mission/index.js";

export const routes = {
  home: { 
    path: "/", 
    component: <Home />, 
    label: "", 
    isExternal: false
  },
  mission: { 
    path: "/missao", 
    component: <Mission />, 
    label: "Nossa missão" ,
    isExternal: false
  },
  products: {
    path: "/solucoes",
    component: <Products />,
    label: "Soluções",
    isExternal: false
  },
  testimonials: {
    path: "/depoimentos-e-cases",
    component: <Depoiments />,
    label: "Depoimentos",
    isExternal: false
  },
  shopping: {
    path: "/onde-comprar",
    component: <Shopping />,
    label: "Onde comprar",
    isExternal: false
  },
  partnership: {
    url: "https://parceiro.protectpool.com.br/",
    label: "Seja um Revendedor",
    isExternal: true
  },
  instalation: {
    path: "/instalacoes",
    component: <Instalation />,
    label: "Instalações",
    isExternal: false
  },
};

export default function Routes_App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        {Object.values(routes).map(({ path, url, component, isExternal }) => {
          if(!isExternal){
            return (
              <Route exact path={path} element={component} key={path} />
            )
          }
        })}
      </Routes>
    </BrowserRouter>
  );
}
