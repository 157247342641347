import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  HeaderContainer,
  Logo,
  MenuButton,
  MenuContainer,
  CloseIconButton,
  SubMenuLinkContainer,
  SubMenuButton,
  MenuLink,
  HeaderContainerContent,
  MenuBackground,
  MenuDesktop,
  SubMenuDesktop,
  ExternalMenuLink,
  SubMenuLinkContainerMobile,
} from "./styled";
import menuzinho from "../../assets/icons/menu_sanduiche.svg";
import xis from "../../assets/icons/xis.svg";
import arrow_blue from "../../assets/icons/arrow_blue.svg";
import logo from "../../assets/logos/logo.png";
import { useState } from "react";
import { routes } from "../../routes";

const productCategories = [
  { label: "Soluções Protectpool", id: "" },
  { label: "Cerca removível", id: "#cercaRemovivel" },
  { label: "Portão com trava magnética", id: "#portaoMag" },
  { label: "Cerca pet", id: "#cercaPet" },
  { label: "Capa térmica", id: "#capaTermica" },
  { label: "Equipamentos", id: "#equipamentos" },
];

export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isProductCategoriesOpen, setIsProductCategoriesOpen] = useState(false);
  const { pathname } = useLocation();
  const ref = useRef(null);

  const handleMenuButtonClick = () => {
    setIsOpen(true);
  };

  const toggleIsProductCategoriesOpen = () => {
    setIsProductCategoriesOpen(!isProductCategoriesOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsProductCategoriesOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <Header 
        onMenuClick={handleMenuButtonClick} 
        onProductCategoriesClick={toggleIsProductCategoriesOpen} 
        onCloseMenu={closeMenu}
        isCategoriesOpen={isProductCategoriesOpen}
      />
      {isOpen && <MenuBackground />}
      <MenuContainer ref={ref} isOpen={isOpen}>
        <CloseButton onClick={closeMenu} />

        {Object.values(routes).map(({ path, url, label, isExternal }) => {
          // landing page, external URL
          if(isExternal) {
            return (
              <ExternalMenuLink key={label} href={url} target="_blank" rel="noopener noreferrer">
                {label}
              </ExternalMenuLink>
            )
          }

          const isProductsPath = path === routes.products.path;
          return isProductsPath ? (
            <ProductsSubMenu
              key={path}
              isMenuOpen={isProductCategoriesOpen}
              onClick={toggleIsProductCategoriesOpen}
              onItemsClick={closeMenu}
            />
          ) : (
            <MenuLink key={path} to={path} isCurrentPath={pathname === path}>
              {label}
            </MenuLink>
          );
        })}
      </MenuContainer>

      {/* <ProductsSubMenuLinks onLinkClick={closeMenu} isOpen={isProductCategoriesOpen} /> */}

    </>
  );
}

function Header({ onMenuClick, onProductCategoriesClick, onCloseMenu, isCategoriesOpen }) {
  const { pathname } = useLocation();

  return (
    <HeaderContainer>
      <HeaderContainerContent>
        <Link to={routes.home.path}>
          <Logo
            src={logo}
            alt="logo da marca com tres gotas de água brancas do canto direito em um fundo azul marinho e a escrita protectpool"
          ></Logo>
        </Link>

        {/* It only shows when width < 768px */}
        <MenuButton
          onClick={onMenuClick}
          aria-label="Ver menu com listagem de páginas"
        >
          <img src={menuzinho} alt="menu sanduiche" aria-hidden></img>
        </MenuButton>

        {/* For width > 768px */}
        <MenuDesktop>
          {Object.values(routes).map(({ path, url, label, isExternal }) => {
            if(isExternal) {
                return (
                    <ExternalMenuLink key={label} href={url} target="_blank" rel="noopener noreferrer">
                        {label}
                    </ExternalMenuLink>
                )
            }

            const isProductsPath = path === routes.products.path;
            return isProductsPath ? (
              <ProductsSubMenu
                key={path}
                isMenuOpen={isCategoriesOpen}
                onClick={onProductCategoriesClick}
                onItemsClick={onCloseMenu}
              />
            ) : (
              <MenuLink key={path} to={path} isCurrentPath={pathname === path}>
                {label}
              </MenuLink>
            );
          })}
        </MenuDesktop>
      </HeaderContainerContent>
    </HeaderContainer>
  );
}

function ProductsSubMenuLinks({ onLinkClick, isOpen }) {
  return (
    <>
      <SubMenuLinkContainer visible={isOpen}>
        {productCategories.map((product) => (
          <Link
            to={`${routes.products.path}${product.id}`}
            key={product.id}
            onClick={onLinkClick}
          >
            {product.label}
          </Link>
        ))}
      </SubMenuLinkContainer>

      <SubMenuLinkContainerMobile visible={isOpen}>
        {productCategories.map((product) => (
          <Link
            to={`${routes.products.path}${product.id}`}
            key={product.id}
            onClick={onLinkClick}
          >
            {product.label}
          </Link>
        ))}
      </SubMenuLinkContainerMobile>
    </>
  );
}

function ProductsSubMenu({ isMenuOpen, onClick, onItemsClick }) {
  const { pathname } = useLocation();
  const disabled = pathname === routes.products.path;

  return (
    <SubMenuDesktop>
      <SubMenuButton onClick={onClick} isOpen={isMenuOpen} selected={disabled}>
        {routes.products.label}
        <img
          src={arrow_blue}
          alt="Seta indicando outro menu"
        ></img>
      </SubMenuButton>
      <ProductsSubMenuLinks onLinkClick={onItemsClick} isOpen={isMenuOpen} />
    </SubMenuDesktop>
  );
}

function CloseButton({ onClick }) {
  return (
    <CloseIconButton onClick={onClick}>
      <img src={xis} alt="menu aberto"></img>
    </CloseIconButton>
  );
}
