import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Container } from "./styled";
export default function CarouselProducts({ images = [] }) {
  return (
    <Container>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        showThumbs={false}
        showStatus={false}
        emulateTouch
        dynamicHeight
      >
        {images.map((element, index) => (
            <img
              src={element}
              key={index}
              alt="Imagens da cerca Protectpool"
              className="imageCarousel"
            />
        ))}
      </Carousel>
    </Container>
  );
}
