import styled from "styled-components";
import * as colors from "../../styles/colors";
import { contentMaxWidth } from "../../data/constants";
import grayBackground from "../../assets/images/gray_background.png";
import { MaxWidthContainer } from "../../components/emailForm/styled";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.Light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .solutions h2 {
    margin-bottom: 30px;
    text-align: center;
  }

  .MenuDiference {
    height: 68px;
    width: 100%;
    background-color: ${colors.Primary0};
  }

  #cercaRemovivel, #cercaPet, #equipamentos {
    background-color: ${colors.GrayExtraLight};
    width: 100%;
    margin: 60px 0;
    padding: 60px 0;
  }

  .content {
    width: ${MaxWidthContainer};
    margin: 0 auto;
    text-align: center;
  }

  .productsGalleryContainer {
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 10px;
  }

  .hiddenMobile {
    display: none;
  }

  .textAndVideo {
    background-image: url(${grayBackground});
    background-color: rgba(34, 69, 157, 1);
    background-blend-mode: overlay;
  }

  .Product h3 {
    border-radius: 10px 10px 0px 0px;
    padding: 6px;
    /* font-size: 1.3rem; */
    background: linear-gradient(${colors.Gradient});
    color: ${colors.Light};
    text-align: center;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .Product a img {
    width: 100%;
    max-height: 310px; 
    object-fit: cover;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

  }

  .ContainerGrid {
    width: 100%;
    background-color: ${colors.Light};
    padding: 0% 0% 5% 0%;
  }

  .titleDivision {
    /* background-color: ${colors.Light}; */
    width: 100%;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  .marginTop {
    margin-top: 15%;
  }
  .titleDivision h1 {
    color: ${colors.Dark0};
    text-align: center;
    padding: 20px 0px 20px 0px;
    /* font-size: 2rem; */
    margin-top: 30px
  }
  img {
    width: 100%;
  }
  .gotaBranca {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  }

  /* .destaqueText {
    width: 90%;
    margin: 5%;
  } */
  .destaqueText h3 {
    color: ${colors.Primary1};
    text-align: center;
    /* font-size: 1.5rem;
    font-family: MontserratBold; */
    margin: 2rem 0;
  }
  .destaqueText h2 {
    color: ${colors.Primary1};
    text-align: center;
    margin: 30px;
    /* font-size: 2rem;
    font-family: MontserratBold; */
  }
  .destaqueText p {
    color: ${colors.Dark0};
    text-align: center;
  }
  .shortDescription, .shortDescriptionLight {
    margin: 30px 0 30px 60px;

    @media (max-width: 1200px) {
      margin: 30px;
    }

    @media (max-width: 768px) {
      margin: 30px;
      padding-top: 30px;
    }
  }
  .shortDescriptionPrimary2 {
    padding: 7%;
    background-color: ${colors.Primary3};
  }
  .shortDescription h3 {
    color: ${colors.Light};
    text-align: center;
    /* font-size: 2.5rem; */
    margin: 0% 0% 5% 0%;
    /* font-family: MontserratBold; */

    @media (max-width: 1200px) {
      padding-top: 50px;
    }
  }
  .shortDescriptionPrimary2 h3 {
    color: ${colors.Light};
    text-align: center;
    /* font-size: 2.5rem; */
    margin: 0% 0% 5% 0%;
    /* font-family: MontserratBold; */
  }

  .shortDescriptionLight {
    color: ${colors.Dark0};
  }

  .shortDescriptionLight h3 {
    margin: 2rem 0;
    text-align: center;
    
  }

  .shortDescriptionLight p {
    text-align: center;
  }

  .shortDescription p {
    color: ${colors.Light};
    text-align: center;
  }
  .shortDescription strong {
    color: ${colors.Secondary0};
  }
  .shortDescriptionPrimary2 p {
    color: ${colors.Light};
    text-align: center;
  }
  .shortDescriptionPrimary2 strong {
    color: ${colors.Secondary0};
  }

  .strongText strong {
    color: ${colors.Light};
  }

  .coresContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 7vh 0% 0 0%;
    padding: 0;
  }
  .colorsResponsiveContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .colorsResponsiveContainer .coresContainer h3 {
    color: ${colors.Primary0};
  }

  
  .telasHastes {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;

    align-items: baseline;
  }

  .telasHastes .haste {
    width: 400px;
    margin-bottom: 0;
  }

  .centralize {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .hasteContainer {
    text-align: center;
    color: ${colors.Primary0};
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .coresContainer img {
    width: 100%;
  }

  .coresContent {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .coresContent div {
    width: 20%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .coresContent div p {
    font-size: 0.7rem;
    text-align: center;
  }
  .imagemPresention {
    width: 100%;
  }
  
  iframe {
    height: 500px;
    width: 100%;
  }

  .videoContainer { 
    max-width: ${contentMaxWidth};
    margin: 50px auto;
    align-items: center;
  }

  .reversecolum {
    display: flex;
    flex-direction: column-reverse;
  }

  .width100{
    width:100%;
    padding: 7% 7% 0% 7%;
  }
  .width100 h3{
    width:100%;
    margin:0;

  }

  .relative {
    position: relative;
  }

  .haste1 {
    position: absolute;
    left: 22%;
    top: 35%;
    text-align: left;
  }
  .haste2 {
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
  }
  .haste3 {
    position: absolute;
    right: 25%;
    top: 35%;
    text-align: left;
  }

  .haste1,.haste2,.haste3 {
    color: ${colors.Dark0};
    font-size: .8rem;
  }

  .hasteContainer img {
    width: 60%!important;
    object-fit: contain;
  }

  .gotas div img {
    object-fit: contain!important;
    width: 50%!important;
  }

  .coresContainer img {
    object-fit: contain!important;
    width: 85%!important;
  }
  @media (max-width: 768px) {
    .Product h3 {
        font-size: 1rem;
    }

    .shortDescription h3 {
        padding-top: 0;
    }

    .shortDescription {
        padding: 50px 0;
    }

    iframe {
        height: 300px;
    }

    .textAndVideo {
        background-color: rgba(34, 69, 157, 1);
    }
  }

  @media (min-width: 1200px) {

    .productsGalleryContainer {
      width: ${contentMaxWidth};
      grid-column-gap: 30px;
      grid-row-gap: 20px;
    }

    .ContainerGrid {
      width: 100%;
      background-color: ${colors.Light};
    }

    .titleDivision h1 {
      width: ${contentMaxWidth};
    }
    
    .titleDivision {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
    }

    .destaqueText {
      width: ${contentMaxWidth};
      margin: 4rem auto 2rem;
      padding: 0;
        text-align: center;
    }

    /* padrao de imagens sem estarem alinhadas */
    .textAndVideo {
      background-color: ${colors.Primary0};
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
      width: ${contentMaxWidth};
      margin: 0 auto;
    }

    .textAndVideoPrimary2 {
      width: ${contentMaxWidth};
      background-color: ${colors.Primary3};
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
    }
    
    .contentLimit {
      display: flex;
      width: 100%;
      gap: 30px;
    }

    .textAndVideo .shortDescription {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 5rem 0px;
      justify-content: center;
      gap: 20px;
    }

    .textAndVideoPrimary2 .shortDescriptionPrimary2 {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 5rem 60px;
      justify-content: center;
      gap: 20px;
    }
    
    .textAndImage {
      display: flex;
      justify-content: center;
      width: ${contentMaxWidth};
      margin: 0 auto;
      gap: 30px;
      align-items: center;
    }

    .textAndImage div {
      width: 50%;
    } 

    .textAndImage h3 {
      margin-bottom: 40px;
    }
    .textAndImage p {
      margin-bottom: 20px;
    }
    .video-height {
      min-height: 500px;
    }

    .shortDescription p, .shortDescriptionLight p {
      text-align: left;
      margin-right: 20px;
    }
    
    .shortDescription h3, .shortDescriptionLight h3 {
      /* font-size: 2.5rem; */
      text-align: left;
      margin-right: 20px;
    }

    .shortDescriptionPrimary2 h3 {
      text-align: left;
      margin-right: 20px;
      /* font-size: 1.2rem; */
    }

    .shortDescriptionPrimary2 p {
      text-align:left;
    }

    .contentLimit .imagemPresention img {
      /* width: 50%; */
      object-fit: cover;
    }

    .contentLimit .imagemPresention {
      width: 50%;
    }

    .contentLimit .imagemPresention iframe {
      width: 100%;
      height: 99.9%;
    }

    .reversecolum {
      display: flex;
      flex-direction: row;
    }
    .imagemPresention {
      height: 100%;
    }
    .coresContainer {
      width: 50%;
    }
    .coresContainer:nth-child(1) {
      /* margin-right: 2%; */
    }

    .corTela {
      height: 246px;
      margin-left:4%;
    }
    .colorsResponsiveContainer {
      display: flex;
      flex-direction: row;
      width: ${contentMaxWidth};
      justify-content: center;
      align-items: center;
    }

    .marginTop {
      margin-top: 0%;
    }

    .cercaPetImg {
      width: 50%;
    }
    .cercaPetImg img {
      width: 50vw;
    }
    .hiddenMobile {
      display: flex;
    }

    .hiddenWeb {
      display: none;
    }

    span {
      display: block;
      background-color: ${colors.Light};
      width: 100%;
      height: 100px;
    }

    h3 strong {
      color: ${colors.Secondary0};
      margin-bottom: 40px;
    }

    .carouselImages {
      width: ${contentMaxWidth};
      margin: 0 auto;
    }
    
    .telasHastes {
      display: flex;
      justify-content: center;
      flex-direction: row;

      align-items: end;
    }

    .telasHastes .haste {
      width: 400px;
      margin-bottom: 0;
    }

    .hasteContainer {
      text-align: center;
      margin-right: -150px;
      color: ${colors.Primary0};
    }

    .hasteContainer h3 {
        margin-bottom: 30px;
    }
  }
  .imagemPresention img{
    height:100%;
  }
`;
