import React from "react";
import Menu from "../../components/menu";
import Footer from "../../components/footerSite";
import { Container } from "./styled";
import capaDaPagina from "../../assets/images/depoimentos_capaDaPagina.png";
import { FeedbackCardCarousel } from "../../components/FeedbackCardCarousel";
import WppButton from "../../components/wppButton";
import { testimonials } from "../../data/testimonials";
import { useInitialScroll } from "../../hooks/useInitialScroll";

export default function Depoiments() {
  useInitialScroll();

  return (
    <>
      <Menu />
      <WppButton />
      <Container>
        <div className="conteudo conteudop">
          <h2>Depoimentos e cases</h2>
        </div>

        <div className="bg_blue">
          <div className="equipe">
            <div className="equipe_text left">
              <h4>MAIS TEMPO DE <strong>QUALIDADE</strong> PARA VOCÊ E SEUS FILHOS</h4>
              <p>
                Não é cerca. É mais tempo com seus filhos. Mais tranquilidade para o seu lar. 
                Mais tempo para o seu dia a dia. Mais diversão para as crianças e menos stress 
                para os pais. A Aline e o Rafael já conquistaram essa liberdade! Confira.
              </p>
            </div>
            <div className="img_container">
            <iframe
              src="https://www.youtube.com/embed/TXe8Cw5skwQ?si=J6zM1c2T_RRnLuT8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>{" "}
            </div>
          </div>
        </div>

        <div className="bg_blue">
          <div className="equipe">
            <div className="img_container">
              <iframe
              src="https://www.youtube.com/embed/7xnrZ3nb-zo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              ></iframe>{" "}
            </div>
            <div className="equipe_text right">
              <h4>A Protectpool é garantia de <strong>segurança</strong> para sua casa!</h4>
              <p>
                Quem conhece nosso trabalho sabe que nosso objetivo é levar mais
                segurança, proteção e praticidade para o seu lar! As famílias
                que investem em nossas soluções atestam a qualidade e garantem:
                “A Protectpool solucionou meu problema!”
              </p>
            </div>
            
          </div>
        </div>

        <div className="bg_blue">
          <div className="equipe reverse">
            <div className="equipe_text left">
              <h4>Seu melhor amigo também merece <strong>proteção especializada!</strong></h4>
              <p>
                A Protectpool tem a solução ideal para você que tem pet em casa
                e não sabe o que fazer para deixá-lo brincar livremente próximo
                à piscina. As nossas cercas de proteção removíveis são perfeitas
                para você que tem um pet em casa e quer ter mais confiança em
                deixar o seu amiguinho brincando sozinho.
              </p>
            </div>
            <div className="img_container">
              <iframe
              src="https://www.youtube.com/embed/f4ZnG5Og7Jw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              ></iframe>{" "}
            </div>
          </div>
        </div>

        <div className="carrousel_comentarios">
          <FeedbackCardCarousel feedbacks={testimonials} />
        </div>
      </Container>
      <Footer />
    </>
  );
}
