/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import FooterSite from "../../components/footerSite";
import Menu from "../../components/menu";
import Gallery from "../../components/gallery";
import { Container, Image } from "./styled";
import WppButton from "../../components/wppButton";
import { useInitialScroll } from "../../hooks/useInitialScroll";
import { ModalImage } from "../../components/ModalImage";

export default function Instalation() {
  useInitialScroll();

  const [hastePretaTelaPretaImages, setHastePretaTelaPretaImages] = useState([])
  const [hastePolidaTelaPretaImages, setHastePolidaTelaPretaImages] = useState([])
  const [telaCinzaImages, setTelaCinzaImages] = useState([])
  const [telaAzulMarinhoImages, setTelaAzulMarinhoImages] = useState([])
  const [telaAzulRoyalImages, setTelaAzulRoyalImages] = useState([])
  const [cercaPetImages, setCercaPetImages] = useState([]);

  useEffect(() => {
    //image importation dynamically
    const importImages = async () => {
      //Cerca Pet
      const cercaPetImagesContext = require.context(
        '../../assets/images/cercaPet',
        false,
        /\.(png|jpe?g|webp)$/
      );

      const cercaPetSources = cercaPetImagesContext.keys().map((file) => {
        return cercaPetImagesContext(file);
      });

      const cercaPet = await Promise.all(cercaPetSources);
      setCercaPetImages(cercaPet);

      //Haste Preta + Tela Preta
      const hastePretaTelaPretaImagesContext = require.context(
        '../../assets/images/hastePretaTelaPreta',
        false,
        /\.(png|jpe?g|webp)$/
      );

      const hastePretaTelaPretaImagesSources = hastePretaTelaPretaImagesContext.keys().map((file) => {
        return hastePretaTelaPretaImagesContext(file);
      });

      const hastePretaTelaPreta = await Promise.all(hastePretaTelaPretaImagesSources);
      setHastePretaTelaPretaImages(hastePretaTelaPreta);

      //Haste Polida + Tela Preta
      const hastePolidaTelaPretaImagesContext = require.context(
        '../../assets/images/hastePolidaTelaPreta',
        false,
        /\.(png|jpe?g|webp)$/
      );

      const hastePolidaTelaPretaImagesSources = hastePolidaTelaPretaImagesContext.keys().map((file) => {
        return hastePolidaTelaPretaImagesContext(file);
      });

      const hastePolidaTelaPreta = await Promise.all(hastePolidaTelaPretaImagesSources);
      setHastePolidaTelaPretaImages(hastePolidaTelaPreta);

      //Tela Cinza
      const telaCinzaImagesContext = require.context(
        '../../assets/images/telaCinza',
        false,
        /\.(png|jpe?g|webp)$/
      );

      const telaCinzaImagesSources = telaCinzaImagesContext.keys().map((file) => {
        return telaCinzaImagesContext(file);
      });

      const telaCinza = await Promise.all(telaCinzaImagesSources);
      setTelaCinzaImages(telaCinza);

      //Tela Azul Marinho
      const telaAzulMarinhoImagesContext = require.context(
        '../../assets/images/telaAzulMarinho',
        false,
        /\.(png|jpe?g|webp)$/
      );

      const telaAzulMarinhoImagesSources = telaAzulMarinhoImagesContext.keys().map((file) => {
        return telaAzulMarinhoImagesContext(file);
      });

      const telaAzulMarinho = await Promise.all(telaAzulMarinhoImagesSources);
      setTelaAzulMarinhoImages(telaAzulMarinho);

      //Tela Azul Royal
      const telaAzulRoyalImagesContext = require.context(
        '../../assets/images/telaAzulRoyal',
        false,
        /\.(png|jpe?g|webp)$/
      );

      const telaAzulRoyalImagesSources = telaAzulRoyalImagesContext.keys().map((file) => {
        return telaAzulRoyalImagesContext(file);
      });

      const telaAzulRoyal = await Promise.all(telaAzulRoyalImagesSources);
      setTelaAzulRoyalImages(telaAzulRoyal);
    };
    importImages();
  }, []);

  return (
    <>
      <Menu />
      <WppButton />
      <Container>
        <h2>
          SAIBA POR QUÊ SOMOS A MAIOR E A MELHOR
        </h2>
        <div className="video">
          <iframe
            src="https://www.youtube.com/embed/a7nXW1i8wbQ?si=N1K9vbhtzOxuGp3-"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          {" "}
        </div>
        {/* <p>
          Com mais de 200 mil metros de cercas instalados, a Protectpool já é sucesso em
          muitas famílias que estavam buscando por mais segurança e
          tranquilidade! Confira abaixo um pouco mais de alguns dos nossos
          trabalhos feitos pelo Brasil.
        </p> */}

        <div className="grayBg">
          <h3>Haste preta + Tela preta</h3>
          <div className="grid">
            {hastePretaTelaPretaImages.map((source, index) => (
              <ModalImage image={source} key={index} alt={`Haste preta + tela preta ${index+1}`} />
            ))}
          </div>
        </div>
        
        <div className="whiteBg">
          <h3><span className="grayColor">Haste polida</span> + Tela preta</h3>
          <div className="grid">
            {hastePolidaTelaPretaImages.map((source, index) => (
              <ModalImage key={index} image={source} alt={`Haste polida + tela preta ${index+1}`} />
            ))}
          </div>
        </div>

        <div className="grayBg">
          <h3>Tela <span className="darkGrayColor">cinza</span></h3>
          <div className="grid">
            {telaCinzaImages.map((source, index) => (
              <ModalImage key={index} image={source} alt={`Tela cinza ${index+1}`} />
            ))}
          </div>
        </div>

        <div className="whiteBg">
          <h3>Tela<span className="blueColor"> azul marinho</span></h3>
          <div className="grid">
            {telaAzulMarinhoImages.map((source, index) => (
              <ModalImage key={index} image={source} alt={`Tela azul marinho ${index+1}`} />
            ))}
          </div>
        </div>

        <div className="grayBg">
          <h3>Tela<span className="lightBlueColor"> azul royal</span></h3>
          <div className="grid">
            {telaAzulRoyalImages.map((source, index) => (
              <ModalImage key={index} image={source} alt={`Tela azul royal ${index+1}`} />
            ))}
          </div>
        </div>

        <div className="whiteBg">
          <h3>Cerca <span className="orangeColor">Pet</span></h3>
          <div className="grid">
            {cercaPetImages.map((source, index) => (
              <ModalImage key={index} image={source} alt={`Cerca Pet Image ${index+1}`} />
            ))}
          </div>
        </div>
      </Container>
      <Gallery />
      <FooterSite />
    </>
  );
}
