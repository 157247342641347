import styled from "styled-components";
import * as colors from "../../styles/colors";

export const Container = styled.div`
    position: fixed;
    z-index:100;
    bottom:20px;
    right:10px;
    width:40px;
    height:40px;

    .backToTopButton{
        border:none;
        border-radius:100%;
        width:100%;
        height:100%;
        background-color:${colors.Primary3};
    }
    .backToTopButton:hover{
        background-color:${colors.Primary2};
    }
    @media (min-width: 1200px){
        right:10px;
        width:45px;
        height:45px;
    }
`