import React from "react";
import { Container} from "./styled";
import wppImage from "../../assets/icons/WppAll.svg";

export default function WppButton(){
    function openLink(link){
        return window.open(`${link}`)
      }
    return(
    <>
        <Container>
            <button className="WppButtonAll" onClick={()=>openLink('https://api.whatsapp.com/send?phone=5544991661906&text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20Protectpool%20e%20gostaria%20de%20obter%20mais%20informações')}>
                <img src={wppImage} alt="Logo do wpp"/>
            </button>
        </Container>
    </>
    )
}