import styled from "styled-components";
import { contentMaxWidth } from "../../data/constants";
import * as colors from "../../styles/colors";
import grayBackground from "../../assets/images/gray_background.png";

export const Container = styled.div`
  background-color: ${colors.Light};
`;

export const MainSection = styled.section`
  padding: 40px 32px;
  min-height: 600px;
  background: linear-gradient(
    90deg,
    ${colors.Primary2} 0%,
    ${colors.Primary0} 100%
  );

  display: flex;
  justify-content: center;
  
`;

export const MainSectionContent = styled.div`
  margin: auto;
  @media (min-width: 1200px) {
    max-width: ${contentMaxWidth};
  }
`;

export const Heading2 = styled.h2`
  color: ${colors.Light};
  text-align: center;
`;

export const PoolImage = styled.img`
  width: 100vw;
  margin-left: -32px;
  margin-top: 8px;

  @media (min-width: 1200px) {
    width: 100%;
    max-height:600px;
    object-fit: cover;
    margin-left: 0;
  }
`;

export const RegionSelectorContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 48px;
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  a {
    text-align: center;
    padding: 14px;
    color: ${colors.Primary3};
    background-color: ${colors.Light}; 
    font-family: MontserratSemiBold;
    margin-top: 8px;
    font-size: 1rem;
    border-radius: 999px;
    transition: .3s ease-in-out;
    width: 100%;

    :hover {
        background-color: ${colors.Primary3};
        color: ${colors.Light};
    }
  }
`;

export const GrayBG = styled.div`
  background-color: ${colors.GrayExtraLight};
`;

export const StoresList = styled.div`
  max-width: ${contentMaxWidth};
  margin: 0px auto;
  padding: 50px 0;
  text-align: center;

  h2 {
    margin: 50px 0;
  }

  h3 {
    margin-top: 60px;
  }
`;

export const StoresContainerList = styled.div`
  display: flex;
  gap: 30px;
  margin: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

export const StoresContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

export const Store = styled.div`
  padding: 30px;
  background-color: ${colors.Light};
  box-shadow: 0 0 8px rgb(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 200px;
  width: 280px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  &.premium {
    background: linear-gradient(135deg, #FCF6BA, #f5c253, #FBF5B7, #ebb33b);
    /* border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(135deg, #FCF6BA, #f5c253, #FBF5B7, #ebb33b) 1;
   border-radius: 10px; */
/* 
    box-sizing: border-box;
    border: 3px;
    background-clip: padding-box!important;
    border: solid 3px #ffc954!important;
    border-radius: 10px; */

    /* &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -3px!important;
      border-radius: inherit!important;
      background: linear-gradient(135deg, #FCF6BA, #f5c253, #FBF5B7, #ebb33b);
    } */
  }
`;

export const Stamp = styled.img`
  position: absolute;
  right: -30px;
  top: -30px;
  width: 80px;
  z-index: 10;
  transition: 0.3s ease;

  &:hover {
    width: 90px;
  }
`;

export const StoreFooter = styled.div`
  display: flex;
  gap: 10px;
`;

export const TelButton = styled.a`
  display: block;
  width: 100%;
  padding: 10px;
  color: ${colors.Light};
  text-align: center;
  background-color: ${colors.Primary3};
  border-radius: 1000px;
  font-family: MontserratSemiBold;
  transition: .3s ease-in-out;

  :hover {
    background-color: ${colors.Primary2};
  }
`;

export const WhatsButton = styled.a`
  display: block;
  border: none;
  outline: none;
  padding: 8px 10px;
  text-align: center;
  margin: auto;
  background-color: #25d366;
  border-radius: 1000px;
  transition: .3s ease-in-out;

  :hover {
    filter: brightness(110%);
  }

  img {
    width: 18px;

  }
`;

export const StoreTitle = styled.div`
  display: flex;
  gap: 10px;

  h5 {
    text-align: left;
  }
`;

export const StoreContent = styled.div`
  margin: 20px 10px;
  text-align: left;

  div {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }
`;

export const Icon = styled.img`
  width: 20px;
  object-fit: contain;
`;

export const Logo = styled.img`
  width: 30px;
  object-fit: contain;
`;

export const MapSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  width: 1140px;

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
}

  @media (max-width: 1200px) {
    width: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const MapText = styled.h2`
  color: ${colors.Light};
  text-align: center;
  font-family: MontserratBold;
  font-size: 1.8rem;

  @media (min-width: 1200px) {
    text-align: start;
  }
`;

export const MapImage = styled.img`
  width: 100%;
  max-width: 500px;
  padding: 20px;
`;

export const SecondarySection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 32px;
  background-color: ${colors.Light};
`;

export const SecondarySectionContent = styled.div`
  max-width: ${contentMaxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;    


export const BoxContent = styled.div`
  background-color: ${colors.Secondary0};
  color: ${colors.Light};
  padding: 60px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgb(0,0,0,0.15);

  background-image: url(${grayBackground});
  background-blend-mode: overlay;
`;

export const Heading1 = styled.h1`
  /* color: ${colors.Secondary0}; */
  text-align: center;
  font-size: 2.5rem;
`;

export const Heading5 = styled.h5`
  color: ${colors.GrayExtraLight};
  text-align: center;
  font-size: 1.25rem;
`;

export const WhatsAppButton = styled.a`
  display: flex;
  justify-content: center;
  gap: 4px;
  color: ${colors.Light};
  background-color: ${colors.WhatsApp};
  border: none;
  border-radius: 24px;
  height: 96px;
  width: fit-content;
  padding: 24px 64px;
  font-family: MontserratBold;
  font-size: 2rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  align-items: center;

  @media (max-width: 768px) {
    padding: 12px 32px;
  }
  
  img {
    width: 32px;
  }

  ::before, ::after{
    content: '';
    display: block;
    position: absolute;
    left: -7px;
    right: -7px;
    top: -7px;
    bottom: -7px;
    border-radius: 100px;
    border: 5px solid ${colors.WhatsApp};
    animation: animar 1.5s linear infinite;
  }

  ::after{
    animation-delay: 0.4s;
  }

  @keyframes animar {
    0%{
        transform: scale(0.5);
        opacity: 0;
    }
    50%{
        opacity: 1;

    }
    100%{
        transform: scale(1.2);
        opacity: 0;

    }
  }

  :active {
    background-color: #01b05b;
    box-shadow: none;
  }
`;
