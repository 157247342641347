import styled from "styled-components";
import {contentMaxWidth} from "../../data/constants";
import * as colors from "../../styles/colors";

export const Container = styled.div`
width:100%;
display:flex;
flex-direction:column;
align-items:center;
cursor: grab;

:active {
  cursor: grabbing;
}



h1{
    text-align:center;
    font-size: 1rem;
    color:${colors.Light};
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    line-height:1.2rem;
    padding:0% 5% 0% 5%;

}
.title{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    min-height:60px;
    max-height:120px;
}

.carousel1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${colors.Primary0}
}

.btnContainer{
    display: flex;
    height: 60%;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 5%;
}

.btnContainerStart {
    display: flex;
    height: 60%;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 5%;

}

.linkRedirect{
    width:50%;
}


.btnRedirect{
  width:100%;  
  border:none;
  background-color:${colors.Secondary0};
  padding:10px;
  border-radius:300000px;
  font-family: MontserratBold;
  font-size: 1rem;
  color:${colors.Light};
  box-shadow:1.5px 1.5px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    font-size: 0.75rem;

  }
}

.blueBtn {
    background-color: ${colors.Primary0}!important;
}

.carousel-root{
  width:100%;
}

.ImageButton img{
    height:100%;
    width:100%;
    object-fit: contain;
    object-position:50% 60%;
}

@media (min-width:1200px){

    .ImageButton img{
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        object-position: 50% 75%;
    }

    .firstImage img{
        object-position: 50% 35%;
    }

    .title h1{
        text-align: center;
        font-size: 2rem;
        color:${colors.Light};
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        line-height:1.2rem;
        padding:0% 5% 0% 5%;
    }

    .title{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        width:100%;
        min-height:80px;
        max-height:160px;
    }

    .btnRedirect{
    width:300px;  
    border:none;
    background-color:${colors.Secondary0};
    padding:10px;
    border-radius:300000px;
    font-family: MontserratBold;
    font-size: 1rem;
    color:${colors.Light};
    box-shadow:1.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    }

    .linkRedirect{
        width:300px;
    }

    .btnContainer{
        margin:auto auto;
        display:flex;
        height:60%;
        justify-content:center;
        align-items:center;
        position:absolute;
        top:50%;
        right:5%;
    }
    .linkRedirectContainer{
        width:${contentMaxWidth};
        display:flex;
        justify-content:right;
    }
    
    .carousel1{
        display:flex;
        align-items:left;
        background-color:${colors.Primary0};
        width:100%;
    }
    .ImageButton{
        width:100%;
    }
}

`
