import styled from "styled-components";
import { contentMaxWidth } from "../../data/constants";
import * as colors from "../../styles/colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5vh 0 0 0;

  h2 {
    width: 80%;
    /* color: ${colors.Secondary0}; */
    text-align: center;
    padding-bottom: 5vh;
    max-width: ${contentMaxWidth};
    font-size: 2.5rem;
    // break text
    word-wrap: break-word;
  }

  p {
    width: 80%;
    color: ${colors.Primary1};
    text-align: center;
    max-width: ${contentMaxWidth};
    font-size: 1rem;
  }

  .video {
    width: 100%;
    height: 30rem;
    margin-bottom: 5rem;
  }

  .video iframe {
    width: 100%;
    height: 100%;
  }

  .grayBg {
    background-color: ${colors.GrayLight};
    width: 100%;
    padding: 64px 0;
  }

  .whiteBg {
    width: 100%;
    padding: 64px 0;
  }

  h3 {
    margin-bottom: 50px;
    text-align: center;
    font-size: 2rem;
  }

  .grayColor {
    color: #878787;
  }
  .darkGrayColor {
    color: #474747;
  }
  .blueColor {
    color: ${colors.Primary0}
  }
  .lightBlueColor {
    color: #00afef;
  }
  .orangeColor {
    color: ${colors.Secondary0}
  }

  @media (max-width: 850px) {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      width: 100%;
      margin: 0 auto;
    }
  }

  @media (min-width: 850px) {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      width: 100%;
      margin: 0 auto;
    }
  }

  @media (min-width: 1200px) {
    .video {
      max-width: ${contentMaxWidth};
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 30px;
      width: ${contentMaxWidth};
      margin: 0 auto;
    }
  }

`;

export const Image = styled.img`

  @media (max-width: 1200px) {
    max-width: 100%;
    object-fit: contain;
  }

  @media (min-width: 1200px) {
    max-width: 100%;
    object-fit: contain;
  }
`;