import Modal from "react-modal-image";

export function ModalImage({ image, alt }) {
  return (
      <>
        <Modal
          small={image}
          medium={image}
          large={image}
          alt={alt}
        />
      </>        
    )
}