import styled from "styled-components";
import { Link } from "react-router-dom";
import * as colors from "../../styles/colors";
import { contentMaxWidth } from "../../data/constants";

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  min-height: 70px;
  align-items: center;
  padding: 0 32px;
  background-color: ${colors.Light};
  width: 100%;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

export const HeaderContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: 1200px) {
    max-width: ${contentMaxWidth};
  }
`;

export const MenuButton = styled.button`
  border: none;
  background-color: transparent;
  height: 32px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const MenuDesktop = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SubMenuDesktop = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export const Logo = styled.img`
  height: 32px;
`;

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 36px;
  cursor: ${(props) => (props.isCurrentPath ? "default" : "pointer")};
  color: ${(props) => (props.isCurrentPath ? colors.Primary2 : colors.Light)};
  font-family: MontserratRegular;
  font-size: 1.25rem;
  width: fit-content;

  @media (min-width: 1200px) {
    height: 48px;
    font-size: 1.5rem;
  }

  @media (min-width: 768px) {
    color: ${(props) => (props.isCurrentPath ? colors.Primary2 : colors.Dark1)};
    font-size: .8rem;
    text-transform: uppercase;

    &:hover {
      color: ${props => colors.Primary2};
    }
  }
`;

export const ExternalMenuLink = styled.a`
    display: flex;
    align-items: center;
    height: 36px;
    cursor: ${(props) => (props.isCurrentPath ? "default" : "pointer")};
    color: ${(props) => (props.isCurrentPath ? colors.Primary2 : colors.Light)};
    font-family: MontserratRegular;
    font-size: 1.25rem;
    width: fit-content;

    @media (min-width: 1200px) {
        height: 48px;
        font-size: 1.5rem;
    }

    @media (min-width: 768px) {
        color: ${(props) => (props.isCurrentPath ? colors.Primary2 : colors.Dark1)};
        font-size: .8rem;
        text-transform: uppercase;

        &:hover {
        color: ${props => colors.Primary2};
        }
    }
`;

export const SubMenuButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background-color: transparent;
  height: 36px;
  cursor: pointer;
  color: ${(props) => (props.selected ? colors.Primary2 : colors.Light)};
  font-family: MontserratRegular;
  font-size: 1.25rem;

  img {
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "none")};
  }

  @media (min-width: 1200px) {
    height: 48px;
    font-size: 1.5rem;
  }

  @media (min-width: 768px) {
    color: ${(props) => colors.Dark1};
    font-size: .8rem;
    text-transform: uppercase;

    &:hover {
      color: ${props => colors.Primary2};
    }
  }
`;

export const SubMenuLinkContainer = styled.nav`

  display: none;
  @media (min-width: 768px) {
    display: flex;
    max-height: ${(props) => (props.visible ? "240px" : "0")};
    opacity: ${(props) => (props.visible ? "100%" : "0")};
    flex-direction: column;
    align-items: left;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: ${(props) => (props.visible ? "8px" : "0")};

    overflow: hidden;

    transition: max-height 0.4s linear, opacity 0.4s linear;

    a {
        display: flex;
        align-items: center;
        height: 32px;
        color: ${colors.Light};
        font-family: MontserratRegular;
        font-size: 0.9rem;
    }

    @media (min-width: 1200px) {
        max-height: ${(props) => (props.visible ? "320px" : "0")};
        a {
        height: 38px;
        font-size: 1rem;
        }
    }

    a {
      color: ${(props) => colors.Dark1};
      font-size: .8rem;
      text-transform: uppercase;

      &:hover {
        color: ${(props) => colors.Primary2}
      }
    }
    display: ${props => props.visible ? 'flex' : 'none'};
    position: fixed;
    background-color: white;
    overflow: visible;
    top: 70px;
    left: 40%;
    z-index: 200;
  }

`;

export const SubMenuLinkContainerMobile = styled.nav`
  display: none;
  @media (max-width: 768px) {
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;

    a {
      color: ${colors.Light};
      font-family: MontserratRegular;
      font-size: 0.9rem;
      transform: uppercase;
      margin-left: 16px;
  }

  };
`;

export const CloseIconButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 24px;
  border: none;
  background-color: transparent;
`;

export const MenuContainer = styled.div`
  position: relative;
  background-color: ${colors.Primary0};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  padding: 28px;
  border-radius: 0 12px 12px 0;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100vw")};
  transition: left 0.3s linear;

  @media (min-width: 1200px) {
    min-width: 27%;
    max-width: 40%;
    padding: 60px;
  }
`;

export const MenuBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: ${colors.Primary0};
  opacity: 56%;
`;
