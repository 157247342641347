import React from "react";
import { Container } from "./styled";
import arrowtoTop from "../../assets/icons/arrowToTop.svg";

export default function BacktoTop(){

    function sliderElement(element){
        const catchElement= document.getElementById(element);
        return ( window.scrollTo(catchElement.offsetLeft,catchElement.offsetTop))
       } 
    
       return(
        <>
            <Container>
                <button className="backToTopButton" onClick={()=>sliderElement("GalleryProducts")}>
                    <img src={arrowtoTop} alt="Imagem de seta apontando para o topo da pagina"/>
                </button>
            </Container>
            </>
       )
}