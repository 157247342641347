import styled from "styled-components";
import * as colors from "../../styles/colors";
import {contentMaxWidth} from "../../data/constants";
import {MaxWidthContainer} from "../../components/emailForm/styled";
import grayBackground from "../../assets/images/gray_background.png";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.Light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .One {
    margin: 10% 0% 10% 0%;
    width: 90%;
    text-align: center;
    padding: 5% 0 3% 0;
  }

  .One h2 {
    color: ${colors.Dark0};
  }

  .aspasUp {
    display: flex;
    width: 20px;
  }

  .aspasDown {
    display: flex;
    justify-content: flex-end;
  }

  .aspasDown img {
    width: 20px;
  }

  .img {
    width: 100%;
  }

  h2 strong {
    color: ${colors.Primary3};
  }

  .shortDescription {
    padding: 5%;
  }

  .shortDescription h3 {
    color: ${colors.Dark0};
    text-align: center;
    margin: 0% 0% 5% 0%;
  }

  .shortDescription p {
    color: ${colors.Dark1};
    text-align: center;
  }

  .imagemPresention {
    /* margin: 10% 30px; */
  }

  .imagemPresention img {
    width: 100%;
  }

  .iconList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
  }

  .iconList div {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .iconList div p {
    margin-top: 5%;
    color: ${colors.Dark0};
    font-family: MontserratBold;
    font-size: 1rem;
    text-align: center;
  }

  .shortDescriptionLight {
    padding: 0% 5% 5% 5%;
    background-color: ${colors.Light};
  }

  .shortDescriptionLight h3 {
    font-family: MontserratBold;
    color: ${colors.Dark0};
    text-align: center;
    margin: 10px;
    font-size: 1.5rem;
  }

  .shortDescriptionLight p {
    color: ${colors.Dark1};
    text-align: center;
    padding: 0% 5% 0% 5%;
  }

  .hiddenMobile {
    display: none;
  }

  .hiddenMobile2 {
    display: none;
  }

  .YoutubeVideo {
    width: 100%;
    aspect-ratio: 16/9;
    margin-top: 30px;
  }

  .YoutubeVideo iframe {
    width: 100%;
    height: 100%;
  }

  .col-2 {
    width: 100%;
  }
  
  .description h3 {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 1200px) {
    .description {
        padding: 5%;
        text-align: center;

        h3 {
            margin-bottom: 1rem;
        }
        
    }

    .videoAndDescription {
        display: flex;
        flex-direction: column-reverse;
    }
  }
  @media (min-width: 1200px) {
    .One {
      width: ${contentMaxWidth};
      margin: 0%;
      text-align: center;
      padding: 40px 0 40px 0;
    }

    .One h2 {
      font-size: 2rem;
    }

    .textAndVideo {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
    }

    .contentLimit {
      display: flex;
      max-width: ${contentMaxWidth};
    }

    .textAndVideo .shortDescription {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 0 30px 0 0;
      justify-content: center;
      gap: 20px;
    }

    .shortDescription h3 {
      font-size: 1.5rem;
      text-align: left;
    }

    .shortDescription p {
      text-align: left;
      margin: 0px;
    }

    .contentLimit .imagemPresention img {
      width: 35vw;
    }

    .contentLimit .imagemPresention iframe {
      width: 50vw;
    }

    .imagemPresention img {
      width: ${MaxWidthContainer};
    }

    .textAndVideo .imagemPresention {
      width: 50%;
      margin-bottom: 0px;
      height: auto;
      display: flex;
      align-items: center;
    }

    .hiddenWeb {
      display: none;
    }

    .hiddenMobile {
      display: flex;
      flex-direction: column;
    }

    .iconList {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: ${contentMaxWidth};
      margin: 48px auto;
      gap: 128px;
    }
    
    .grayBg {
      background-color: ${colors.GrayLight};
      width: 100%;
      margin: 64px 0;
    }

    .iconList div {
      margin-bottom: 0px;
    }

    .iconList p {
      text-align: center;
    }

    .col-2 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 50px 0;
      gap: 70px;
      align-items: center;
      width: ${contentMaxWidth};
    }

    .shortDescriptionLight {
      width: ${contentMaxWidth};
      margin: 5vh 0px 5vh 0px;
      padding: 0;
    }

    .shortDescriptionLight p {
      width: ${contentMaxWidth};
      margin: 0;
      padding: 0;
    }

    .imagemPresention {
      width: ${contentMaxWidth};
      margin-bottom: 0;
    }

    .YoutubeVideo {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .aspasUp {
      width: 35px;
    }

    .aspasDown img {
      width: 35px;
    }
  }
`;

export const Clients = styled.div`
    padding: 5rem 0;
    margin: 3rem 0;
    width: 100%;
    background-color: #F8F8F8;
    h3 {
        text-align: center;
        margin-bottom: 2rem;
    }

    .logos {
        display: grid;
        max-width: ${contentMaxWidth};
        margin: 0 auto;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
            margin: 0 auto;
        }
    }
`;

export const Logo = styled.img`
    width: 10rem;
    object-fit: contain;
    margin: 0 auto;
`;
