import styled from "styled-components";
import * as colors from "../../styles/colors";

export const Container = styled.div`
  width: 100%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: ${colors.Light};
`;

export const CardHeader = styled.div`
  background-color: ${colors.Secondary0};
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
`;

export const CardLogo = styled.img`
  height: 66px;
`;

export const CardContent = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
`;

export const CardTitle = styled.h2`
  color: ${colors.Secondary0};
  text-align: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.Primary1};
  text-align: center;
`;

export const BoldInfo = styled.p`
  font-weight: 600;
`;
