import styled from "styled-components";
import * as colors from "../../styles/colors";

export const StatusContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${colors.Primary1};
  opacity: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20%;
  gap: 16px;

  @media (min-width: 1200px) {
    .LottieImage {
      width: 130px;
    }
  }
`;

export const StatusImage = styled.img`
  transform: ${(props) => (props.isSuccess ? "none" : "rotateX(180deg)")};
`;

export const StatusMessage = styled.h2`
  color: ${colors.Light};
  text-align: center;
  font-size: 2rem;
  font-family: MontserratBold;
  margin-top: -20px;
`;

export const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: 560px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: space-evenly;
  }

  div {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  label {
    padding-left: 5%;
    color: ${colors.Dark0};
  }

  input {
    color: ${colors.Dark0};
    border: 1.5px solid ${colors.Dark0};
    width: 100%;
    height: 56px;
    border-radius: 50px;
    padding: 2% 3%;
    font-family: MontserratRegular;
    font-size: 1rem;
  }

  input::placeholder {
    color: ${colors.Dark2};
    padding: 3%;
  }

  select {
    background-color: ${colors.Light};
    text-align: center;
    font-family: MontserratRegular;
    font-size: 1rem;
    color: ${colors.Dark0};
    border: 1.5px solid ${colors.Dark0};
    width: 100%;
    height: 56px;
    border-radius: 50px;
    padding: 0 3%;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
  }

  button {
    border: none;
    background-color: ${colors.Secondary0};
    color: ${colors.Light};
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    height: 48px;
    border-radius: 24px;
    padding: 0 64px;
  }
`;
