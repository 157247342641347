import styled from "styled-components";
import * as colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 440px;
  margin: auto;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FakeSelectContainer = styled.div`
  position: relative;
`;

export const FakeSelectInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: MontserratRegular;
  font-size: 1rem;
  color: ${colors.Light};
  background-color: ${(props) =>
    props.isOpen ? colors.Primary1 : colors.Primary3};
  ${colors.Primary3};
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 8px;
  cursor: pointer;
`;

export const FakeSelectOptionContainer = styled.div`
  position: absolute;
  background-color: ${colors.Light};
  left: 0;
  right: 0;
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`;

export const FakeSelectOption = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 24px;
  color: ${colors.Primary1};
  font-family: MontserratBold;
  font-size: 1rem;

  :hover {
    color: ${colors.Light};
    background-color: ${colors.Primary3};
  }
`;
