import React from "react";
import Routes from "./routes/index.js"
import GlobalStyle from "./styles/globalStyle";

function App() {
  return (
    <div className="App">
      <>
      <Routes/>
      <GlobalStyle/>
      </>
    </div>
  );
}

export default App;
