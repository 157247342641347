import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 105px 0;
  max-width: 400px;

  .carousel {
    overflow: visible;
  }

  .slider-wrapper {
    overflow: visible;
  }

  .slide div {
    padding-bottom:0;
  }

  .slide div div {
    padding: 20px;
  }

  cursor: grab;

  :active {
    cursor: grabbing;
  }
`;

export const CardContainer = styled.div`
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 40px;
`;
