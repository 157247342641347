import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {CloseButton, Container, Image, ImageContainer, Thumbnail,} from "./style";
import xis from "../../assets/icons/xis.svg";

function GalleryImage({thumbnailSrc, fullSizeSrc}) {
    const [isOpen, setIsOpen] = useState(false);
    const fullSizeRef = useRef(null);

    const openModal = () => {
        document.body.style.overflow = "hidden";
        setIsOpen(true);
    };

    const closeModal = () => {
        document.body.style.overflow = "visible";
        setIsOpen(false);
    };

    useEffect(() => {
        return closeModal;
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (fullSizeRef.current && !fullSizeRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    return (
        <>
            <Thumbnail src={thumbnailSrc} onClick={openModal}/>
            {isOpen && (
                <ImageContainer>
                    <Image src={fullSizeSrc} ref={fullSizeRef}/>
                    <CloseButton onClick={closeModal}>
                        <img src={xis} alt="fechar visualização"/>
                    </CloseButton>
                </ImageContainer>
            )}
        </>
    );
}

export default function Gallery() {
    const apiUrl = 'https://protectpool-dev.bitzenwebsites.net/api';
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/categorias?populate=images`)
            .then(({data}) => setCategories(data.data))
            .catch((error) => setError(error))
    }, [])

    return (
        <Container>
            {
                categories.map((category) => {
                    if(category.attributes.images.data) {
                        return (
                            <>
                                <h2>{category.attributes.name}</h2>
                                <div className="gallery">
                                    {
                                        category.attributes.images.data.map((image) => {
                                            return (
                                                <GalleryImage
                                                    thumbnailSrc={image.attributes.url}
                                                    fullSizeSrc={image.attributes.url}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )

                    }
                })
            }
        </Container>
    );
}
