export const Primary1 = "#242D65";
export const Primary0 = "#22459D";
export const Primary2 = "#117ABF";
export const Primary3 = "#00AEEF";
export const Secondary1 = "#BF5804";
export const Secondary0 = "#F57F20";
export const Light = "#FFFFFF";
export const Dark0 = "#000000";
export const Dark1 = "#6D6E71";
export const Dark2 = "#BCBDC0";
export const Dark3 = "#DEDEDE";
export const Dark4 = "#000C4E";
export const GrayLight = "#E7E8EA";
export const GrayExtraLight = "#f2f2f2";
export const Gradient = "90deg, #22459D 0%, #00ADEE 100%";
export const WhatsApp = "#00E676";

export const gradienteSimulator = "135deg, #001373 20%, #011BA0 43%, #0524FF 60%, #2987FF 80%";

export const sliderStyles ={
    track: {
      backgroundColor: "#CCC",
      width: 50000,
      height:7,
    },
    active: {
      backgroundColor: Secondary0,
    },
    thumb: {
      width: 17,
      height: 17,
      backgroundColor: Secondary0,
    },
    disabled: {
      opacity: 0.5,
    },
  };