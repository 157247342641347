import styled from "styled-components";

export const Container = styled.div`
cursor: grab;

:active {
  cursor: grabbing;
}

.imageCarousel {
    width: 100%;
    height: 700px;
    object-fit: cover;
    object-position: bottom;
}

`