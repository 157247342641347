import React from "react";
import {Container} from "./styled";
import Menu from "../../components/menu";
import timesSquare from "../../assets/images/times-square.webp";
import ceoProtectpool from "../../assets/images/ceo-protectpool.webp";
import Footer from "../../components/footerSite";
import WppButton from "../../components/wppButton";
import {useInitialScroll} from "../../hooks/useInitialScroll";
import Podcast from "../podcast";
import missaoIcon from "../../assets/icons/missao-icon.png"
import visaoIcon from "../../assets/icons/visao-icon.png"
import valoresIcon from "../../assets/icons/valores-icon.png"

export default function Mission() {
    useInitialScroll();

    return (
        <>
            <Menu/>
            <WppButton/>
            <Container>
                <div className="One">
                    <h1>TRABALHAMOS COM UM PROPÓSITO:<br />SALVAR VIDAS</h1>
                </div>

                <div className="video">
                    <iframe
                        src="https://www.youtube.com/embed/JBMcAcUjvmw?si=7-ZO0rwfrtjqqRSW"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    {" "}
                </div>

                <div className="descOne">
                  <p>
                    Nosso compromisso com a sociedade brasileira é combater incessantemente o afogamento infantil, 
                    que hoje é a primeira causa morte de crianças de 0 a 4 anos no Brasil. Faça parte dessa jornada! 
                  </p>
                </div>

                <div className="spacing"></div>

                <div className="mission_container">
                    <div className="mission">
                        <div>
                            <img
                                src={missaoIcon}
                                alt="Missão icon"
                            />
                            <h2>Missão</h2>
                            <p>
                                Levar tranquilidade, praticidade e segurança as famílias através
                                da cerca de proteção removível para piscinas, uma solução moderna
                                e inovadora.
                            </p>
                        </div>

                        <div>
                            <img
                                src={visaoIcon}
                                alt="Visão icon"
                            />
                            <h2>Visão</h2>
                            <p>
                                Auxiliar nossos clientes na escolha da melhor solução em segurança
                                para sua família, com produtos de proteção para piscinas de
                                qualidade.
                            </p>
                        </div>

                        <div>
                            <img
                                src={valoresIcon}
                                alt="Valores icon"
                            />
                            <h2>Valores</h2>
                            <p>
                                Resultado, compromisso e responsabilidade. Essas são as diretrizes
                                que fazem parte do nosso relacionamento com funcionários,
                                revendedores e clientes.{" "}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="spacing"></div>

                <div>
                    <div className="equipe">
                        <div className="equipe_text">
                            <h2>Nosso diferencial é acreditar nas pessoas!</h2>
                            <p>
                                Contamos com uma equipe 100% treinada e capacitada para levar até você a melhor solução
                                quando o assunto é segurança infantil e pet! Nossos colaboradores passam por constantes
                                treinamentos para que você receba em sua casa um produto de extrema qualidade e
                                sofisticação.

                            </p>
                        </div>
                        <div className="img_container">
                            <img
                                className="imgens"
                                src={timesSquare}
                                alt="foto na times square"
                            ></img>
                        </div>
                    </div>
                </div>

                <div className="compromisso">
                    
                    <div className="bg_blue">
                        <div className="compromisso_conteudo">

                            <div className="parag">
                                <h2>
                                  Somos a firmeza na voz e o brilho nos olhos.
                                </h2>
                                <p className="ajuste">
                                  A sua expectativa é o que nos faz trabalhar e inovar. Dedicar-se profundamente àquilo que fomos predestinados.
                                  O que nos move é entregar a melhor sensação que você possa ter: se sentir seguro.
                                  Somos inquietos, e esse desconforto com a mesmice nos faz inovar. 
                                </p>
                                <p className="ajuste">
                                  O nosso inconformismo é a origem da sua proteção.
                                  Mais do que conhecimento para inovar, temos comprometimento com qualidade e a relevância do que produzimos.
                                  Assumimos o risco.
                                  O resultado? Mais tempo para você viver momentos tranquilos com quem você ama. 
                                </p>
                                <p className="ajuste">
                                  Queremos juntos continuar levando a felicidade por caminhos sólidos e ter você ao nosso 
                                  lado para fazer parte de algo transformador.
                                </p>
                                <p className="ajuste">
                                  Juntos, somos movidos por um ideal:
                                  A tranquilidade da sua família, de pessoas para pessoas.
                                </p>
                                <div className="ajuste">
                                    <h4>Márcio Francisco</h4>
                                    <p>CEO Grupo Protectpool</p>
                                </div>
                            </div>
                            <div className="img_container img_comp">
                                <img
                                    className="imgens"
                                    src={ceoProtectpool}
                                    alt="foto do dono da empresa Sr Márcio em frente a piscina do Copacabana Palace"
                                ></img>
                            </div>
                        </div>
                    </div>
                </div>

                <Podcast />
            </Container>
            <Footer/>
        </>
    );
}
