import React from "react";
import Menu from "../../components/menu";
import { Container } from "./styled";
import Footer from "../../components/footerSite";
import CarouselProducts from "../../components/carouselProducts";
import { useInitialScroll } from "../../hooks/useInitialScroll";
import BacktoTop from "../../components/backtoTop";
import WppButtonLeft from "../../components/wppButtonLeft";
// importando icones
import gotaPreta from "../../assets/icons/gotaPreta.svg";
import gotaBranca from "../../assets/icons/gotaBranca.svg";
import gotaCinza from "../../assets/icons/gotaCinza.svg";
import gotaMarinho from "../../assets/icons/gotaMarinho.svg";
import gotaMarrom from "../../assets/icons/gotaMarrom.svg";
import gotaRoyal from "../../assets/icons/gotaRoyal.svg";
import telas from "../../assets/images/nossosProdutos_telas.png";
import chumbadores from "../../assets/images/nossosProdutos_chumbadores.png";
import furadeira from "../../assets/images/furadeira.png"
import guiaAngular from "../../assets/images/guia-angular.png"

// importacoes novas
import capaTermica1 from "../../assets/images/nossosProdutos_capaTermica_1.png";
import capaTermica2 from "../../assets/images/nossosProdutos_capaTermica_2.png";
import capaTermica3 from "../../assets/images/nossosProdutos_capaTermica_3.png";
import capaTermica5 from "../../assets/images/nossosProdutos_capaTermica_5.png";
import cercaPet1 from "../../assets/images/nossosProdutos_cercaPet_1.webp";
import cercaPet2 from "../../assets/images/nossosProdutos_cercaPet_2.webp";
import cercaPet3 from "../../assets/images/nossosProdutos_cercaPet_3.png";
import cercaPet4 from "../../assets/images/nossosProdutos_cercaPet_4.png";
import cercaRemovivel1 from "../../assets/images/nossosProdutos_cercaRemovivel_1.webp";
import cercaRemovivel2 from "../../assets/images/nossosProdutos_cercaRemovivel_2.webp";
import cercaRemovivel3 from "../../assets/images/nossosProdutos_cercaRemovivel_3.webp";
import cercaRemovivel4 from "../../assets/images/nossosProdutos_cercaRemovivel_4.webp";
import cercaRemovivel5 from "../../assets/images/nossosProdutos_cercaRemovivel_5.webp";
import portaoMagneticoGrid from "../../assets/images/nossosProdutos_portaoMagnetico_grid.webp";
import cercaRemovivelGrid from "../../assets/images/cerca-removivel.jpg";
import cercaPetGrid from "../../assets/images/cerca-pet.jpeg";
import capaTermicaGrid from "../../assets/images/nossosProdutos_capaTermica_grid.png";
import hastes from "../../assets/images/hastes.webp"
import { Link } from "react-router-dom";

export default function Products() {
  useInitialScroll();

  return (
    <>
      <Menu />
      <BacktoTop />
      <WppButtonLeft />
      <Container id="GalleryProducts">
        {/* Grid */}
        <div className="MenuDiferenceWhite
        " ></div>
        <div className="solutions">
          <div className="titleDivision">
            <h1>Soluções Protectpool</h1>
          </div>
          <div className="productsGalleryContainer">
            <div className="Product">
              <h3>
                Cerca <br className="hiddenWeb" /> Removível
              </h3>
              <Link to="/solucoes#cercaRemovivel">
                <img
                  src={cercaRemovivelGrid}
                  alt="Imagem de cerca removivel Protectpool"
                />
              </Link>
            </div>
            <div className="Product">
              <h3>
                Portão <br className="hiddenWeb" /> Magnético
              </h3>
              <Link to="/solucoes#portaoMag">
                <img
                  src={portaoMagneticoGrid}
                  alt="Imagem de portão magnético Protectpool"
                />
              </Link>
            </div>
            <div className="Product">
              <h3>Cerca Pet</h3>
              <Link to="/solucoes#cercaPet">
                <img src={cercaPetGrid} alt="Imagem de cerca pet Protectpool" />
              </Link>
            </div>
            <div className="Product">
              <h3>Capa Térmica</h3>
              <Link to="/solucoes#capaTermica">
                <img
                  src={capaTermicaGrid}
                  alt="Imagem da capa térmica Protectpool"
                />
              </Link>
            </div>
          </div>
        </div>
        {/* Primeiro Produto */}
        <div className="MenuDiferenceWhite" id="cercaRemovivel">
          <div className="content">

            <div className="titleDivision">
              <h1>Cerca Removível</h1>
            </div>
            <div className="carouselImages">
              <CarouselProducts images={[cercaRemovivel1, cercaRemovivel2, cercaRemovivel3, cercaRemovivel4, cercaRemovivel5]} />
            </div>
            <div className="destaqueText">
              <h3>
                A MELHOR CERCA DE PROTEÇÃO REMOVÍVEL PARA
                PISCINAS VOCÊ ENCONTRA AQUI!
              </h3>
            </div>

            {/* Produto um web */}
            <div className="textAndVideo hiddenMobile2">
              <div className="contentLimit">
                <div className="shortDescription">
                  <h3>
                    Cerca de proteção removível <strong>Prime + </strong>Protectpool
                  </h3>
                  <p>
                    As soluções de proteção Infantil e Pet Protectpool oferecem até 5 anos
                    de garantia para que a sua única preocupação constante seja aproveitar
                    os bons momentos com sua família em segurança.
                    Desenvolvidas com hastes de alumínio e tela de poliéster revestidas de PVC,
                    contém alta resistência e durabilidade, com aditivos Anti-Cloro, Anti-Fungos,
                    Anti-UV e Anti-Chamas.
                  </p>
                  <p>
                    Nossas cercas são completamente removíveis e de fácil manuseio por um adulto, suportando 80 kg de impacto.
                  </p>
                </div>
                <div className="imagemPresention">
                  <iframe
                    src="https://www.youtube.com/embed/9dG2R9HEr_w?si=pXWkds_Ip3BMFz-V"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen={false}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          {/* Final do produto 1 web */}
          <div className="centralize">

            <div className="telasHastes">
              <div className="hasteContainer">
                <h3>Hastes</h3>
                <div className="relative">
                  <img src={hastes} alt="hastes" className="haste" />

                  <p className="haste1">Fosco <br />Anodizado</p>
                  <p className="haste2">Preto Eletrostático</p>
                  <p className="haste3">Natural <br />Polido</p>
                </div>
              </div>
              <div className="colorsResponsiveContainer">
                <div className="coresContainer">
                  <h3>Cor da Tela</h3>
                  <div className="coresContent gotas">
                    <div>
                      <img src={gotaPreta} alt="Imagem de gota Preta" />
                      <p>Preta</p>
                    </div>
                    <div>
                      <img src={gotaMarinho} alt="Imagem de gota Azul Marinho" />
                      <p>Azul Marinho</p>
                    </div>
                    <div>
                      <img src={gotaRoyal} alt="Imagem de gota Royal" />
                      <p>Azul Royal</p>
                    </div>
                    <div>
                      <img src={gotaCinza} alt="Imagem de gota Cinza" />
                      <p>Cinza</p>
                    </div>
                  </div>
                  <img
                    className="corTela"
                    src={telas}
                    alt="Imagem que demonstra todas as cores de telas da Protectpool"
                  />
                </div>
                <div className="coresContainer">
                  <h3>Cor do Chumbador</h3>
                  <div className="coresContent gotas">
                    <div>
                      <img src={gotaPreta} alt="Imagem de gota Preta" />
                      <p>Preta</p>
                    </div>
                    <div>
                      <img src={gotaMarrom} alt="Imagem de gota Azul Marrom" />
                      <p>Marrom</p>
                    </div>
                    <div>
                      <img src={gotaCinza} alt="Imagem de gota cinza" />
                      <p>Cinza</p>
                    </div>
                    <div>
                      <img
                        className="gotaBranca"
                        src={gotaBranca}
                        alt="Imagem de gota Branca"
                      />
                      <p>Branco</p>
                    </div>
                  </div>
                  <img
                    className="corTela"
                    src={chumbadores}
                    alt="Imagem que demonstra todas as cores de chumbadores da Protectpool"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Segundo produto */}
        <div className="MenuDiferenceWhite" id="portaoMag"></div>
        <div className="titleDivision">
          <h1>Portão com Trava Magnética e Retorno Automático</h1>
        </div>
        {/* <div className="destaqueText">
          <p>
            Nosso Portão conta com sistema de Retorno Automático e fechamento
            com Trava Magnética e chave, impossibilitando sua abertura por
            crianças sem a presença dos pais.
          </p>
        </div> */}

        <div className="textAndVideo hiddenMobile2">
          <div className="contentLimit reversecolum">
            <div className="shortDescription">
              <h3>
                Tecnologia que proporciona <strong>+</strong> praticidade e segurança
              </h3>
              <p>
                Nosso Portão conta com sistema de Retorno Automático e fechamento com Trava
                Magnética e chave, impossibilitando sua abertura por crianças sem a presença dos pais.
              </p>
              <p>
                Produto com design elegante, desenvolvido com o melhor em tecnologia de trava magnética.
                Com botão de elevação mais ergonômico e resistente às crianças, o Portão com Trava possui
                indicador visual que fornece status de bloqueado ou desbloqueado.
              </p>
              <p>
                A trava possui chave e pino de segurança para fechamento e conta com garantia vitalícia de todo o seu conjunto.
              </p>
            </div>
            <div className="imagemPresention video-height">
              <iframe
                src="https://www.youtube.com/embed/CRTMYdJWBlY?si=l52jYohXJWcuuR7t"
                title="Vimeo video player"
                width="640"
                height="360"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
              ></iframe>
            </div>
          </div>
        </div>


        {/* Terceiro Produto */}
        <div className="MenuDiferenceWhite" id="cercaPet">
          <div className="titleDivision">
            <h1>Cerca Pet</h1>
          </div>
          <div className="carouselImages">
            <CarouselProducts images={[cercaPet4, cercaPet1, cercaPet2, cercaPet3]} />
          </div>
          <div className="destaqueText">
            <h3>Invista na segurança do seu melhor amigo!</h3>
          </div>


          <div className="textAndVideo hiddenMobile2">
            <div className="contentLimit">
              <div className="shortDescription">
                <h3>
                  Cerca removível Pet, feita para levar proteção para seu melhor amigo!
                </h3>
                <p>
                  Seu melhor amigo também precisa de proteção quando o assunto é piscina.
                </p>
                <p>
                  Para isso, criamos soluções para proteger seu pet no dia a dia. Com altura de 50cm, a Cerca Removível Pet Protectpool é indicada para pets de pequeno porte, trazendo mais tranquilidade e diversão para o seu cachorrinho.
                </p>
              </div>
              <div className="imagemPresention">
                <iframe
                  src="https://www.youtube.com/embed/fEbNKutFZ10?si=Kq86WdHEPEa3CMIO"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={false}
                ></iframe>
              </div>
            </div>
          </div>
        </div>


        {/* Quarto Produto */}
        <div className="MenuDiferenceWhite" id="capaTermica"></div>
        <div className="titleDivision">
          <h1>Capa Térmica</h1>
        </div>
        {/* <div className="carouselImages">
          <CarouselProducts images={[capaTermica5, capaTermica2, capaTermica3, capaTermica1]} />
        </div> */}
        {/* Mobile Content */}
        {/* <div className="shortDescription hiddenWeb">
          <h3>
            Economize com produtos químicos e demasiados gastos com energia.
            <strong> Invista</strong> agora em uma capa térmica!
          </h3>
          <p>
            A Capa Térmica para Piscina aumenta o calor na água o que faz
            reduzir custos de aquecimento, também impede que sujeiras, folhas,
            insetos e outros resíduos entrem na piscina fazendo economizar de
            30% a 60% na utilização de produtos químicos, pois a Capa Térmica
            para Piscina reduz a evaporação, e os mantém na água por mais tempo.
          </p>
        </div>
        <img
          className="hiddenWeb"
          src={capaTermica1}
          alt="Imagem da capa termica"
        /> */}
        {/* Final do conteudo mobile  */}
        {/* Conteudo WEB Diferente */}

        <div className="textAndVideo">
          <div className="contentLimit">
            <div className="shortDescription">
              <h3>
                Economize com produtos químicos e demasiados gastos com energia. <strong>Invista</strong> agora em uma capa térmica!
              </h3>
              <p>
                A Capa Térmica para Piscina aumenta a temperatura da água em até 12ºC. Com isso os custos de aquecimento da piscina podem ser reduzidos em até 50%. A Capa Térmica também impede que sujeiras, folhas, insetos e outros resíduos entrem na piscina.
              </p>
              <p>
                É possível economizar de 30% a 60% na utilização de produtos químicos, pois a Capa Térmica para Piscina reduz a evaporação, e os mantém na água por mais tempo.
              </p>
              <p>
                O principal motivo da perda de calor de uma piscina é a evaporação e a Capa Térmica para Piscina serve como uma barreira, maximiza a retenção de calor e reduz em até 98% a evaporação natural da água.
              </p>
            </div>
            <div className="imagemPresention">
              <img
                src={capaTermica1}
                alt="Imagem da capa termica"
              />
            </div>
          </div>
        </div>

        <div className="destaqueText">
          <h2>Enrolador de Capa Térmica</h2>
        </div>

        <div className="textAndVideo">
          <div className="contentLimit">
            <div className="shortDescription">
              <h3>
                Tenha <strong>+</strong> praticidade e conforto ao utilizar a sua piscina
              </h3>
              <p>
                Desenvolvido especialmente para uso com as Capas Térmicas, este enrolador de capa de piscina evita dobras, diminui rasgos e danos nas bolhas, aumentando assim a vida útil da Capa térmica.

              </p>
              <p>
                O Enrolador de Capa de piscina pode ser utilizado em qualquer formato de piscinas (retangulares, ovais e irregulares). Seu design moderno garante adequação ao ambiente da piscina.

              </p>
            </div>
            <div className="imagemPresention video-height">
              <iframe
                src="https://www.youtube.com/embed/TzfXyvH4bdk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
              ></iframe>
            </div>
          </div>
        </div>

        <div id="equipamentos">
          <div className="titleDivision">
            <h1>Equipamentos</h1>
          </div>

          <div className="textAndVideo">
            <div className="contentLimit">
              <div className="shortDescription">
                <h3>Furadeira de piso <strong>profissional</strong> Protectpool</h3>
                <p>
                  Perfuratriz Profissional Protectpool, desenvolvida exclusivamente para instalação
                  de Cercas Removiveis Protectpool. Também pode ser utilizada para instalação escadas
                  inox, rede de biribol e condutores elétricos e hidráulicos em construções de piscinas.
                </p>
                <p>
                  Possui rebolo (broca) diamantada com refrigeração à água, ajustes de inclinação,
                  estrutura leve e de fácil manuseio, para uma aplicação perfeita sem danificar o piso.
                </p>
              </div>
              <div className="imagemPresention video-height">
                <img src={furadeira} alt="furadeira" />
              </div>
            </div>
          </div>

          <div className="videoContainer">
            <iframe
              src="https://www.youtube.com/embed/6QGNJAEBGEY?si=QDYz9dtWUPPgtrgv"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>
          </div>

          <div className="textAndVideo">
            <div className="contentLimit">
              <div className="imagemPresention video-height">
                <img src={guiaAngular} alt="Guia angular" />
              </div>
              <div className="shortDescription">
                <h3>Guia angular de <strong>furação</strong> Protectpool</h3>
                <p>
                  Desenvolvido para facilitar o dia a dia dos nossos parceiros, o Guia Angular Protectpool
                  é uma ferramenta de entrada no mercado. Com ele é possível realizar as perfurações seguindo
                  os ângulos corretos para a instalação da Cerca Removível para Piscina Protectpool.
                </p>
                <p>
                  Possui ajuste de angulação, travamento automático e resfriamento da broca diamantada por água,
                  além de base construída em aço garantindo maior durabilidade.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
