export const testimonials = [
  {
    name: "Kamon Lopes",
    testimonial:
      "Os produtos são de excelente qualidade. O atendimento é Ótimo. Sem contar que numa das compras que eu fiz paguei um valor expressivo a + e me devolveram prontamente, ou seja, sobretudo são honestos. Recomendo!",
  },
  {
    name: "Luis Alfredo",
    testimonial:
      "Segurança  que sua família  precisa com qualidade  vc encontra aqui.",
  },
  {
    name: "Carlos Rubens",
    testimonial:
      "Tive uma satisfação muito grande em ser muito bem atendido e compreendido no que precisava. Recomendo e aprovei o produto por isso estou aqui deixando meu comentário, pois o que é bom tem que ser compartilhado.",
  },
  {
    name: "Guilherme Lunardi",
    testimonial:
      "Produto e instalação de excelentes qualidade.  Após acompanhar a instalação tive a certeza de que demanda equipamento e pessoal treinado, o que a Protecpool oferece!",
  },
];
