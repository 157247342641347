const UFs = Object.freeze({
    AC: "AC",
    AL: "AL",
    AP: "AP",
    AM: "AM",
    BA: "BA",
    CE: "CE",
    DF: "DF",
    ES: "ES",
    GO: "GO",
    MA: "MA",
    MT: "MT",
    MS: "MS",
    MG: "MG",
    PA: "PA",
    PB: "PB",
    PR: "PR",
    PE: "PE",
    PI: "PI",
    RJ: "RJ",
    RN: "RN",
    RS: "RS",
    RO: "RO",
    RR: "RR",
    SC: "SC",
    SP: "SP",
    SE: "SE",
    TO: "TO",
});

export const storesByUF = {
    [UFs.AC]: {name: "Acre", cities: []},
    [UFs.AL]: {name: "Alagoas", cities: []},
    [UFs.AP]: {name: "Amapá", cities: []},
    [UFs.AM]: {name: "Amazonas", cities: []},
    [UFs.BA]: {name: "Bahia", cities: []},
    [UFs.CE]: {name: "Ceará", cities: []},
    [UFs.DF]: {name: "Distrito Federal", cities: []},
    [UFs.ES]: {name: "Espírito Santo", cities: []},
    [UFs.GO]: {name: "Goiás", cities: []},
    [UFs.MA]: {name: "Maranhão", cities: []},
    [UFs.MT]: {name: "Mato Grosso", cities: []},
    [UFs.MS]: {name: "Mato Grosso do Sul", cities: []},
    [UFs.MG]: {name: "Minas Gerais", cities: []},
    [UFs.PA]: {name: "Pará", cities: []},
    [UFs.PB]: {name: "Paraíba", cities: []},
    [UFs.PR]: {name: "Paraná", cities: []},
    [UFs.PE]: {name: "Pernambuco", cities: []},
    [UFs.PI]: {name: "Piauí", cities: []},
    [UFs.RJ]: {name: "Rio de Janeiro", cities: []},
    [UFs.RN]: {name: "Rio Grande do Norte", cities: []},
    [UFs.RS]: {name: "Rio Grande do Sul", cities: []},
    [UFs.RO]: {name: "Rondônia", cities: []},
    [UFs.RR]: {name: "Roraima", cities: []},
    [UFs.SC]: {name: "Santa Catarina", cities: []},
    [UFs.SP]: {name: "São Paulo", cities: []},
    [UFs.SE]: {name: "Sergipe", cities: []},
    [UFs.TO]: {name: "Tocantins", cities: []},
};
