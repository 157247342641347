import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Container } from "./styled";
import { Link } from "react-router-dom";
import home_carousel_1 from "../../assets/images/carousel/BANNER-PROTECT-POOL.webp"
import home_carousel_2 from "../../assets/images/carousel/banner_autoridade.webp"
import home_carousel_3 from "../../assets/images/carousel/BANNER_REVENDEDOR.png"

import banner_rascunho from "../../assets/images/banner-rascunho.webp"


export default function CarouselHome() {
  return (
    <Container>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        autoPlay={true}
        interval={6000}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        emulateTouch
      >
        <div className="carousel1">
          {/* <div className="title">
            <h1>Nosso objetivo é levar mais segurança para quem você ama!</h1>
          </div> */}
          <div className="ImageButton firstImage">
            <img 
              src={home_carousel_1}
              alt="Foto da cerca ProtectPool protegendo um bebe de ter acesso"
            />
            <div className="btnContainerStart">
              <div className="linkRedirectContainer">
                <Link className="linkRedirect" to={"/solucoes"}>
                    <button className="btnRedirect">Saiba mais</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="carousel1">
          {/* <div className="title">
            <h1>Seja você um Parceiro de Sucesso Protectpool!</h1>
          </div> */}
          <div className="ImageButton">
            <img
              src={home_carousel_2}
              alt="Duas fotos de cercas removíveis de proteção para piscina"
            />
            <div className="btnContainerStart">
              <div className="linkRedirectContainer">
              <Link className="linkRedirect" to={"/instalacoes"}>
                <button className="btnRedirect blueBtn">Saiba mais</button>
              </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="carousel1">
          {/* <div className="title">
            <h1>Seja você um Parceiro de Sucesso Protectpool!</h1>
          </div> */}
          <div className="ImageButton">
            <img
              src={home_carousel_3}
              alt="Foto da cerca ProtectPool protegendo um bebe de ter acesso"
            />
            <div className="btnContainer">
              <div className="linkRedirectContainer">
              <a className="linkRedirect" href="https://parceiro.protectpoolbrasil.com.br/" target="_blank" rel="noreferrer">
                <button className="btnRedirect">Seja um parceiro</button>
              </a>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </Container>
  );
}
