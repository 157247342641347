import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useInitialScroll() {
  const { hash } = useLocation();

  useEffect(() => {
    let element = undefined;

    if (hash) {
      element = document.querySelector(hash);
    }

    if (element) {
      element.scrollIntoView({ block: "start", behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [hash]);
}
