import React from "react";

import {Container} from "./styled";
import Menu from "../../components/menu/index";
import youtube from "../../assets/icons/youtube.svg";
import spotify from "../../assets/icons/spotify.svg";
import Footer from "../../components/footerSite";
import WppButton from "../../components/wppButton";
import {useInitialScroll} from "../../hooks/useInitialScroll";

export default function Podcast() {
    useInitialScroll();

    function openLink(link) {
        return window.open(`${link}`);
    }

    return (
        <>
            <Container>
                <h2>ProtectCast</h2>
                <div className="Responsiveclass">
                    <div className="Content">
                        <p>
                          Você gostaria de saber mais sobre nossa história e nossa cultura? Conhecer mais sobre 
                          assuntos pertinentes à nossa caminhada, ao que fazemos e como chegamos à liderança do 
                          mercado? 
                        </p>
                    </div>
                    <div className="videoYoutube">
                        <iframe
                            src="https://www.youtube.com/embed/NsskTHee20E"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                    <div className="Content">
                        <p>
                          Foi pensando nisso que lançamos o nosso <b>ProtectCast</b>, o Podcast do grupo Protectpool! 
                          Acompanhe nossos conteúdos e fique por dentro de tudo o que fazemos, da nossa missão 
                          e valores!
                        </p>
                    </div>
                </div>
                <button
                    className="btnContainer"
                    onClick={() =>
                        openLink(
                            "https://www.youtube.com/watch?v=NsskTHee20E&list=PLqF-OK6XKMJOBBZsdwthwODIATkvq-nn2&ab_channel=Protectpool-CercaRemov%C3%ADvelParaPiscina"
                        )
                    }
                >
                    <img src={youtube} alt="icone do youtube"></img>
                    Playlist ProtectCast
                </button>
                <button
                    className="btnContainer"
                    onClick={() =>
                        openLink("https://open.spotify.com/show/3MW1wUi4yxkodyYT2y4RXe")
                    }
                >
                    <img src={spotify} alt="icone do spotify"></img>
                    ProtectCast
                </button>
            </Container>
        </>
    );
}
