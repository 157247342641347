import { createGlobalStyle } from "styled-components";
import montserratExtraLight from "../assets/fonts/Montserrat-ExtraLight.ttf";

import montserratLight from "../assets/fonts/Montserrat-Light.ttf";
import montserratRegular from "../assets/fonts/Montserrat-Regular.ttf";
import montserratMedium from "../assets/fonts/Montserrat-Medium.ttf";
import montserratSemiBold from "../assets/fonts/Montserrat-SemiBold.ttf";
import montserratBold from "../assets/fonts/Montserrat-Bold.ttf";
import montserratExtraBold from "../assets/fonts/Montserrat-ExtraBold.ttf";

export default createGlobalStyle`

@font-face {
  font-family: MontserratExtraLight;
  src: url(${montserratExtraLight});
}

@font-face {
  font-family: MontserratLight;
  src: url(${montserratLight});
}

@font-face {
  font-family: MontserratRegular;
  src: url(${montserratRegular});
}

@font-face {
  font-family: MontserratMedium;
  src: url(${montserratMedium});
}

@font-face {
  font-family: MontserratSemiBold;
  src: url(${montserratSemiBold});
}

@font-face {
  font-family: MontserratBold;
  src: url(${montserratBold});
}

@font-face {
  font-family: MontserratExtraBold;
  src: url(${montserratExtraBold});
}

@font-face {
  font-family: MontserratLight;
  src: url(${montserratLight});
}

@font-face {
  font-family: MontserratRegular;
  src: url(${montserratRegular});
}

@font-face {
  font-family: MontserratMedium;
  src: url(${montserratMedium});
}

@font-face {
  font-family: MontserratBold;
  src: url(${montserratBold});
}

*{
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
button{
    cursor: pointer;
}
a{
    text-decoration: none;
}
ul{
    list-style: none;
}

h1{
    font-family: MontserratBold;
    font-size: 2.5rem;
    text-transform: uppercase;
}
h2{
    font-family: MontserratBold;
    font-size: 2rem;
    text-transform: uppercase;
}
h3{
    font-family: MontserratBold;
    font-size: 1.6rem;
    text-transform: uppercase;
}
h4{
    font-family: MontserratSemiBold;
    font-size: 1.4rem;
    text-transform: uppercase;
}
h5{
    font-family: MontserratSemiBold;
    font-size: 1.2rem;
    text-transform: uppercase;
}
h6{
    font-family: MontserratMedium;
    font-size: 0.88rem;
    text-transform: uppercase;
}
p{
    font-family: MontserratRegular;
    font-size: 0.9rem;
}
.small{
    font-family: MontserratRegular;
    font-size: 0.6rem;
}

  @media (min-width: 1200px) {
    html {
      font-size: 100%;
    }
  }

`