import React, { useState, useEffect } from "react";
import { StoresFilter } from "../../components/StoresFilter";
import Menu from "../../components/menu";
import Footer from "../../components/footerSite";
import WppButton from "../../components/wppButton";
import storesList from "../../data/stores.json"
import logo from "../../assets/logos/logo-simbolo.png"
import phone from "../../assets/icons/phone.svg"
import pin from "../../assets/icons/pin.svg"
import wappIcon from "../../assets/icons/whatsapp-icon.png"
import aviso from "../../assets/icons/aviso.png"
import { Link } from 'react-router-dom';
import franqueado from "../../assets/images/stamp/garantia-4.png"
import {
  Container,
  MainSection,
  Heading2,
  PoolImage,
  SecondarySection,
  Heading1,
  Heading5,
  WhatsAppButton,
  RegionSelectorContainer,
  MapImage,
  MainSectionContent,
  SecondarySectionContent,
  MapSection,
  MapText,
  StoresContainer,
  Store,
  Icon,
  Logo,
  StoreContent,
  StoreTitle,
  StoreFooter,
  TelButton,
  WhatsButton,
  StoresList,
  StoresContainerList,
  GrayBG,
  BoxContent,
  Stamp,
} from "./styled";
import Intro from "../../assets/images/ondeComprar_intro.png"
import whatsAppIcon from "../../assets/icons/whatsapp.svg";
import brasil from "../../assets/ilustrations/brasil.svg";
import { useInitialScroll } from "../../hooks/useInitialScroll";

const whatsAppLink = `https://api.whatsapp.com/send?phone=5544991661906&text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20Protectpool%20e%20gostaria%20de%20falar%20com%20um%20Consultor%20Especialista`;

export default function Shopping() {
  useInitialScroll();

  const [selectedCity, setSelectedCity] = useState()
  const [selectedUF, setSelectedUF] = useState()
  const [filteredStores, setFilteredStores] = useState()

  const setCity = (city) => {
    setSelectedCity(city)

    setStoresList(city)
  }

  const setUF = (UF) => {
    setSelectedUF(UF)
  }

  const setStoresList = (city) => {
    if(!city || !selectedUF) return
    const list = storesList[selectedUF].filter((store) => {
      const _city = store.city.split(' - ')[0]
      return _city === city
    })

    setFilteredStores(list)
  }
  
  return (
    <Container>
      <Menu />
      <WppButton />
      <SecondarySection>
        <SecondarySectionContent>
          <Heading1>
            Presente em todo o território nacional
          </Heading1>
        </SecondarySectionContent>
      </SecondarySection>
      <MainSection>
        <MainSectionContent>
          <MapSection>
            <div>
              <MapText>Encontre a unidade <br/>mais próxima</MapText>
              <RegionSelectorContainer>
                <StoresFilter 
                  stores={storesList}
                  setCity={setCity}
                  setUF={setUF}
                />
                <Link to="/onde-comprar#nao-encontrei">Não encontrei minha cidade</Link>
              </RegionSelectorContainer>
            </div>
            {selectedCity ? (
              <StoresContainer>
                {filteredStores.map((store, index) => {
                  const checkWhatsApp = () => {
                    if(store.tel2) {
                      if(store.tel2.startsWith("(")) {
                        const whatsAppNumber = store.tel2.replace(/\D/g, '')
                        return whatsAppNumber
                      }
                    } else {
                      if(store.tel1.startsWith("(")) {
                        const whatsAppNumber = store.tel1.replace(/\D/g, '')
                        return whatsAppNumber
                      } else return false
                    }
                  }

                  return (
                    <Store key={index} className={store.premium && 'premium'}>
                      {store.premium && (
                        <Stamp src={franqueado} />
                      )}
                      <div>
                        <StoreTitle>
                          <Logo src={logo} alt="logo" />
                          <h5>
                            {store.storeName}
                          </h5>
                        </StoreTitle>

                        <StoreContent>
                          <div>
                            <Icon src={phone} alt="phone icon" />
                            <p>{store.tel1}</p>
                          </div>

                          {store.tel2 && (
                            <div>
                              <Icon src={phone} alt="phone icon" />
                              <p>{store.tel2}</p>
                            </div>
                          )}

                          <div>
                            <Icon src={pin} alt="pin icon" />
                            <p><strong>{store.city}</strong></p>
                          </div>
                        </StoreContent>
                      </div>

                      <StoreFooter>
                        <TelButton href={"tel:"+store.tel1}>Ligar</TelButton>
                        {checkWhatsApp() && (
                          <WhatsButton 
                            href={"https://api.whatsapp.com/send?phone=55"+checkWhatsApp()+"&text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20Protectpool%20e%20gostaria%20de%20falar%20com%20um%20Consultor%20Especialista"}
                            target="_blank"
                          >
                            <img src={wappIcon} alt="whatsapp icon" />
                          </WhatsButton>
                        )}
                      </StoreFooter>

                    </Store>
                  )
                })}
              </StoresContainer>
            ) : (
              <MapImage src={brasil} />
            )}
          </MapSection>
        </MainSectionContent>
      </MainSection>
      <SecondarySection id="nao-encontrei">
        <BoxContent>
          <SecondarySectionContent>
            <Heading1>
              Não encontrou um parceiro de sucesso na sua região?
            </Heading1>
            <Heading5>
              Calma, a Protectpool não é Líder de Mercado à toa. <br/>Nossa equipe
              direto da Fábrica ajudará na Segurança da sua família
            </Heading5>
            <WhatsAppButton href={whatsAppLink} rel="noopener" target="_blank">
              <img src={whatsAppIcon} alt="Ícone do WhatsApp" aria-hidden />
              WhatsApp
            </WhatsAppButton>
          </SecondarySectionContent>
        </BoxContent>
      </SecondarySection>

      {/* stores list */}
      <GrayBG>

        <StoresList>
          <h2>NOSSAS UNIDADES</h2>

          {Object.keys(storesList).map((state) => (
            <div key={state}>
              <h3>{state}</h3>

              <StoresContainerList>
                {storesList[state].map((store, index) => {
                  const checkWhatsApp = () => {
                    if(store.tel2) {
                      if(store.tel2.startsWith("(") && store.tel2.length > 14) {
                        const whatsAppNumber = store.tel2.replace(/\D/g, '')
                        return whatsAppNumber
                      }
                    } else {
                      if(store.tel1.startsWith("(") && store.tel1.length > 14) {
                        const whatsAppNumber = store.tel1.replace(/\D/g, '')
                        return whatsAppNumber
                      } else return false
                    }
                  }
                  return (
                    <Store key={index} className={store.premium && 'premium'}>
                      {store.premium && (
                        <Stamp src={franqueado} />
                      )}
                      <div>
                        <StoreTitle>
                          <Logo src={logo} alt="logo" />
                          <h5>
                            {store.storeName}
                          </h5>
                        </StoreTitle>

                        <StoreContent>
                          <div>
                            <Icon src={phone} alt="phone icon" />
                            <p>{store.tel1}</p>
                          </div>

                          {store.tel2 && (
                            <div>
                              <Icon src={phone} alt="phone icon" />
                              <p>{store.tel2}</p>
                            </div>
                          )}

                          <div>
                            <Icon src={pin} alt="pin icon" />
                            <p><strong>{store.city}</strong></p>
                          </div>
                        </StoreContent>
                      </div>

                      <StoreFooter>
                        <TelButton href={"tel:"+store.tel1}>Ligar</TelButton>
                        {checkWhatsApp() && (
                          <WhatsButton 
                            href={"https://api.whatsapp.com/send?phone=55"+checkWhatsApp()+"&text=Ol%C3%A1%2C%20vim%20pelo%20site%20da%20Protectpool%20e%20gostaria%20de%20falar%20com%20um%20Consultor%20Especialista"}
                            target="_blank"
                          >
                            <img src={wappIcon} alt="whatsapp icon" />
                          </WhatsButton>
                        )}
                      </StoreFooter>
                    </Store>
                  
                )})}
              </StoresContainerList>
            </div>
          ))}

        </StoresList>
      </GrayBG>

      <Footer />
    </Container>
  );
}
