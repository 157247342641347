import React from "react";
import { FeedbackCard } from "../FeedbackCard";
import { Container, CardContainer } from "./styled";
import { Carousel } from "react-responsive-carousel";

export function FeedbackCardCarousel({ feedbacks }) {
  return (
    <Container>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        centerMode
        centerSlidePercentage={80}
        emulateTouch
      >
        {feedbacks.map((feedback, index) => {
          const { name, testimonial } = feedback;
          return (
            <CardContainer key={index}>
              <FeedbackCard name={name} testimonial={testimonial} />
            </CardContainer>
          );
        })}
      </Carousel>
    </Container>
  );
}
