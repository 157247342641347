import styled from "styled-components";
import * as colors from "../../styles/colors"
import {contentMaxWidth} from "../../data/constants";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;

  h2 {
    color: ${colors.Dark0};
    font-size: 2rem;
  }

  .Spotify {
    width: 90%;
    margin: 0 auto;
  }

  .Content {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;

  }

  .Content p {
    color: ${colors.Dark0};
    text-align: center;
  }

  .btnContainer {
    width: 90%;
    height: 46px;
    background-color: ${colors.Primary3};
    border: none;
    margin-bottom: 15px;
    border-radius: 3000px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    color: ${colors.Light};
    font-family: MontserratBold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 200ms;
    font-size: 1.5rem;
  }

  .btnContainer img {
    height: 25px;
    width: 40px;
    margin-right: 5px;
  }

  .btnContainer:hover {
    background-color: ${colors.Primary1};
  }

  .videoYoutube {
    width: 100%;
    height: 200px;
  }

  .videoYoutube iframe {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1200px) {

    min-height: 100vh;
    justify-content: start;

    .Responsiveclass {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: ${contentMaxWidth};
      gap: 20px;
    }

    .Content p {
      text-align: center;
    }

    .btnContainer {
      width: 500px;
      height: 46px;
    }

    .videoYoutube {
      width: ${contentMaxWidth};
      height: 563px;
    }

  }
`