import styled from "styled-components";
import * as colors from "../../styles/colors";
import { contentMaxWidth } from "../../data/constants";

export const Container = styled.div`
  color: ${colors.Primary3};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  h2 {
    width: 80%;
    max-width: ${contentMaxWidth};
    text-align: center;
  }

  .gallery {
    padding: 1% 0 2% 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    justify-content: center;
  }

  @media (min-width: 1200px) {
    .gallery {
      max-width: ${contentMaxWidth};
      gap: 20px;
    }
  }
`;

export const ThumbnailContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(34, 69, 157, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-height: 80vh;
  max-width: 92vw;

  @media (min-width: 1200px) {
    max-width: ${contentMaxWidth};
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
`;
