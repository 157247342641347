import styled from "styled-components";


export const Container = styled.div`
    position: fixed;
    z-index:100;
    bottom:20px;
    left:10px;
    width:40px;
    height:40px;

.WppButtonAll{
    background-color:transparent;
    border:none;
    width:100%;
}

@media (min-width: 1200px){
    width:60px;
    height:60px;
}
    
`