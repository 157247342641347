import React from "react";
import { Container } from "./style";
import aviso from "../../assets/icons/aviso.svg";
import { socialLinks } from "../../data/footerContent";

function SocialLink({ href, imgSrc, imgAlt }) {
  return (
    <a className="link" rel="nofollow noreferrer" target="_blank" href={href}>
      <img src={imgSrc} alt={imgAlt}></img>
    </a>
  );
}

export default function FooterSite() {
  return (
    <Container>
        <div className="rodape">
            <div className="conteudo_rodape">
            <p className="text_rodape">
                Todos os direitos reservados. Este site ou qualquer parte dele
                (fotos, textos, vídeos) não pode ser reproduzido ou usado de forma
                alguma sem autorização expressa, por escrito, da Protectpool Cerca
                de Proteção Removível para Piscina.
            </p>
            </div>
        </div>
      <div className="conteudo">
        <div className="perguntas">
          <div className="redes">
            <h5>Redes Sociais</h5>
            <div className="icons">
              {socialLinks.map(({ href, imgSrc, imgAlt }) => (
                <SocialLink href={href} imgSrc={imgSrc} imgAlt={imgAlt} key={href} />
              ))}
            </div>
          </div>

          <div className="atendimento atend_web">
            {/*<h5>Atendimento</h5>*/}
            <p className="small">
              Segunda à Sexta 08:00 - 12:00 e 13:00 - 18:00
            </p>
            <p className="small">Telefone: 0800 006 7472</p>
            <p className="small">Whatsapp: &#40;44&#41; 99166-1906</p>
          </div>

        </div>

        <div className="atend">
          <div>
            <div className="atendimento">
              <h2>Unidades</h2><br />
              <h5 className="cidade">Maringá - PR</h5>
              <p className="small">Av. Arq. Nildo Ribeiro da Rocha, 868</p>
              <p className="small">Jardim Ipanema</p>
              <p className="small">0800 006 7472</p>
            </div>

            <div className="atendimento">
              <h5 className="cidade">São Paulo - SP</h5>
              <p className="small">Av. das Garoupas, 200</p>
              <p className="small">Balneário São Francisco</p>
              <p className="small">&#40;11&#41; 9 9998-2112</p>
            </div>

            <div className="atendimento">
              <h5 className="cidade">São José Dos Campos - SP</h5>
              <p className="small">Vale do Paraíba e Região</p>
              <p className="small">Jardim Ipanema</p>
              <p className="small">&#40;12&#41; 9 8701-5652</p>
              <p className="small">&#40;11&#41; 9 8323-9824</p>
            </div>
          </div>
        </div>
      </div>
      
    </Container>
  );
}
