import React from "react";
import { Container, ClientName, Testimonial, FiveStarsImage } from "./styled";
import five_stars from "../../assets/ilustrations/five_stars.svg";

export function FeedbackCard({ name, testimonial }) {
  return (
    <Container>
      <FiveStarsImage src={five_stars}></FiveStarsImage>
      <ClientName>{name}</ClientName>
      <Testimonial>{testimonial}</Testimonial>
    </Container>
  );
}
