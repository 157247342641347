import insta from "../assets/icons/instagram.svg";
import face from "../assets/icons/facebook.svg";
import youtube from "../assets/icons/youtube.svg";
import spotify from "../assets/icons/spotify.svg";
import email from "../assets/icons/email.svg";

export const faqContent = [
  {
    question: "Onde consigo comprar uma cerca para minha família?",
    answer: (
      <p className="small textOpen">
        Olá papai e mamãe. Você pode entrar em contato com uma Unidade de
        Atendimento. <a href="/shopping">Clique aqui</a> e encontre o Parceiro
        Protectpool mais próximo de sua residência. Se preferir, nos chame no
        WhatsApp &#40;44&#41; 99166-1906 ou ligue – SAC 0800 006 7472.
      </p>
    ),
  },
  {
    question: "Consigo remover a minha cerca em alguma necessidade?",
    answer: (
      <p className="small textOpen">
        Sim. Nossas cercas são 100% removíveis e você pode armazená-las em um
        pequeno espaço. Mas é claro, por um adulto responsável. Nosso material
        contém travas de segurança e sistemas de tensionamento de forma que
        impossibilitam a remoção da proteção por uma criança de 0 a 6 anos.
      </p>
    ),
  },
  {
    question: "Consigo comprar pela internet?",
    answer: (
      <p className="small textOpen">
        Não!!! E é por isso que a Protectpool se diferencia no mercado. Muito
        mais do que a cerca, estamos lidando com a proteção de seus filhos. Por
        isso, um olhar técnico e uma instalação profissional com equipamentos
        adequados faz toda a diferença no resultado final. Antes de comprar,
        fale com um Consultor Especialista Protectpool.
      </p>
    ),
  },
  {
    question: "Não encontrei um parceiro de sucesso na minha região",
    answer: (
      <p className="small textOpen">
        Calma, não somos líderes de mercado à toa.
        <br></br>
        <br />
        Nossa equipe Direto da Fábrica ajudará na Segurança da sua família.
        Entre em contato pelo WhatsApp (44) 99166-1906 ou ligue – SAC 0800 006
        7472.
      </p>
    ),
  },
  {
    question: "Por que a Protectpool?",
    answer: (
      <p className="small textOpen">
        Simples. A Protectpool é líder de mercado e especialista em proteção
        infantil e pet em ambientes de piscina há quase 10 anos. Com mais de
        16 mil famílias atendidas e com a confiança de marcas multinacionais como
        o Grupo Belmond Copacabana Palace e Fairmont Copabana, a Protectpool é a
        escolha certa para as famílias que querem realizar um investimento certo
        e seguro na segurança de seus filhos.
      </p>
    ),
  },
  {
    question: "Como funciona a instalação da cerca de proteção?",
    answer: (
      <>
        <iframe
          className="textOpen"
          src="https://www.youtube.com/embed/XEd3p52TJ2s"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </>
    ),
  },
];

export const socialLinks = [
  {
    href: "https://www.instagram.com/protectpool/",
    imgSrc: insta,
    imgAlt: "ícone instagram",
  },
  {
    href: "https://www.facebook.com/protectpool",
    imgSrc: face,
    imgAlt: "ícone facebook",
  },
  {
    href: "https://www.youtube.com/channel/UCygE-UTKMKEIp7oPE_N7Jig",
    imgSrc: youtube,
    imgAlt: "ícone youtube",
  },
  {
    href: "https://open.spotify.com/show/3MW1wUi4yxkodyYT2y4RXe",
    imgSrc: spotify,
    imgAlt: "ícone spotify",
  },
  {
    href: "mailto:contato@protectpool.com.br",
    imgSrc: email,
    imgAlt: "ícone email",
  },
];
