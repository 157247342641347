import styled from "styled-components";
import * as colors from "../../styles/colors";
import { contentMaxWidth } from "../../data/constants";
import grayBackground from "../../assets/images/gray_background.png";

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${colors.Light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  .conteudo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .conteudop {
    padding: 3vh 0 0 0;
  }

  h2 {
    padding-bottom: 1%;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  img {
    width: 100%;
  }

  h4 {
    color: ${colors.Primary3};
    text-align: center;
    padding-bottom: 2%;
  }

  h4 strong {
    color: ${colors.Secondary0};
  }

  p {
    color: ${colors.Light};
    text-align: center;
  }

  .imgens {
    width: 100%;
  }

  .bg_blue {
    width: 100%;
    background-image: url(${grayBackground});
    background-color: rgba(34, 69, 157, 1);
    background-blend-mode: overlay;
    margin-bottom: 5rem;
  }

  .equipe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

      
  .reverse {
    display: flex;
    flex-direction: column-reverse; 
}

  .equipe_text {
    padding: 7% 0;
    width: 80%;
    text-align: center;
    color: ${colors.Light};
  }
  
  .equipe_text h4 {
    color: ${colors.Light};
    font-family: MontserratBold;
    font-size: 1.8rem;
  }

  .equipe_text p {
    font-size: 1rem;
  }

  .img_container {
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 500px;
  }

  .carrousel_comentarios {
    width: 100%;
    padding: 0 32px;
    margin: -5rem 0;
  }

  .carrousel_comentarios p {
    color: ${colors.Dark0};
  }

  @media (min-width: 1200px) {
    .conteudop {
      margin-top: 1rem;
      max-width: ${contentMaxWidth};
    }

    .conteudo {
      max-width: ${contentMaxWidth};
      justify-content: center;
      align-items: center;
    }

    .bg_blue {
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
    }

    .equipe {
      max-width: 100%;
      flex-direction: row;
    }

    .equipe img {
      width: 50vw;
    }

    .equipe div {
      width: 50%;
    }

    .equipe_text {
      padding: 0;
    }

    .left {
      margin-left: 20%;
      margin-right: 30px;
    }

    .right {
      margin-right: 20%;
      margin-left: 30px;
    }

    .equipe_text p, .equipe_text h4 {
      text-align: left;
      padding-left: 30px;
    }


    iframe {
      width: 50vw;
    }

    .carrousel_comentarios {
      max-width: ${contentMaxWidth};
      overflow: hidden;
    }
  }`;
